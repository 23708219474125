import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UseXverse } from '../../../components/connect-wallet-modal/hooks/useXverse';
import { AUTHENTICATED_STATUS, NOT_AUTHENTICATED_STATUS, useStore } from '../../../store';
import { useClaimRewardsUnisat } from '../useClaimRewardsUnisat';
import { useClaimRewardsXverse } from '../useClaimRewardsXverse';
import { useStakeUnisat } from '../useStakeUnisat';
import { useStakeXverse } from '../useStakeXverse';

const StakingForm = ({
  usersData,
  usersDataLoading,
  stakeData,
  setOpenSuccessModal,
  drkToStake,
  setDrkToStake,
  setClaimRewardsDialogOpen,
}) => {
  const { ordinalsPublicKey, ordinalsAddress } = UseXverse();

  const [error, setError] = useState('');
  const {
    auth: { status, address, walletType },
  } = useStore();

  const unisat = window.unisat;
  const stakeMutation = useStakeUnisat();
  const xVerseStakeMutation = useStakeXverse();

  const claimRewardsUnisatMutation = useClaimRewardsUnisat();
  const claimRewardsXverseMutation = useClaimRewardsXverse();

  const setPercentageDrk = (e, percent) => {
    const balanceAvailableForStaking = calculateBalanceAvailableForStaking();
    const calculatedDrkPercentage = (balanceAvailableForStaking * percent) / 100;

    setDrkToStake(calculatedDrkPercentage.toFixed(2));
  };

  const calculateBalanceAvailableForStaking = () => {
    const balance = Number(usersData?.balanceDRK);
    const transferableBalance = Number(usersData?.transferableDRK);

    return Math.abs((balance - transferableBalance) / 10 ** 18);
  };

  const handleInputChange = (e) => {
    setDrkToStake(e.target.value);
  };

  const stakeUnisat = async () => {
    const publicKey = await unisat.getPublicKey();

    stakeMutation.mutate(
      { publicKey: publicKey, address: address, amount: drkToStake },
      {
        onSuccess: async () => {
          setOpenSuccessModal(true);
        },
        onError: async () => {
          setError('Something went wrong');
        },
      }
    );
  };

  const stakeXverse = async () => {
    xVerseStakeMutation.mutate(
      { publicKey: ordinalsPublicKey, address: ordinalsAddress, amount: drkToStake },
      {
        onSuccess: async () => {
          setOpenSuccessModal(true);
        },
        onError: async () => {
          setError('Something went wrong');
        },
      }
    );
  };

  const checkValidityOfStaking = () => {
    if (status === NOT_AUTHENTICATED_STATUS) {
      setError('You need to connect to your wallet first');
      return false;
    }

    if (!drkToStake) {
      setError('Please select amount of DRK to stake');
      return false;
    }

    const balanceAvailableForStaking = calculateBalanceAvailableForStaking();
    const notSufficientBalance = drkToStake > balanceAvailableForStaking;

    if (notSufficientBalance) {
      setError('Not sufficient balance of DRK tokens');
      return false;
    }

    setError('');
    return true;
  };

  const stake = () => {
    const isStakingValid = checkValidityOfStaking();
    if (!isStakingValid) return;

    if (walletType === 'unisat') {
      stakeUnisat();
    } else if (walletType === 'xverse') {
      stakeXverse();
    }
  };

  const claimRewards = () => {
    if (walletType === 'unisat') {
      claimRewardsUnisat();
    } else if (walletType === 'xverse') {
      claimRewardsXverse();
    }
  };

  const claimRewardsUnisat = async () => {
    const publicKey = await unisat.getPublicKey();

    claimRewardsUnisatMutation.mutate(
      { publicKey: publicKey, address: address },
      {
        onSuccess: async () => {
          setError('');
          setClaimRewardsDialogOpen(true);
        },
        onError: async () => {
          setError('Something went wrong');
        },
      }
    );
  };

  const claimRewardsXverse = async () => {
    claimRewardsXverseMutation.mutate(
      { publicKey: ordinalsPublicKey, address: ordinalsAddress },
      {
        onSuccess: async () => {
          setError('');
          setClaimRewardsDialogOpen(true);
        },
        onError: async () => {
          setError('Something went wrong');
        },
      }
    );
  };

  return (
    <div className="filter__group align-items-center">
      <div className="d-flex gap-5">
        <div>
          <label className="filter__label fs-6 d-flex flex-column">
            {usersData?.balanceDRK && !usersDataLoading && status === AUTHENTICATED_STATUS ? (
              <>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="stakingBalance" style={{ position: 'fixed' }}>
                      DRK tokens available for staking, calculated as your current DRK balance minus transferable DRK.
                    </Tooltip>
                  }
                >
                  <p className="mb-0 d-flex justify-content-between">
                    <span>Available</span>{' '}
                    <b data-testid="available-balance">{calculateBalanceAvailableForStaking().toFixed(2)} DRK</b>
                  </p>
                </OverlayTrigger>{' '}
                <p className="mb-0 mt-1 d-flex justify-content-between">
                  <span>Currently staking </span>
                  <b>{Number(stakeData?.lockedAmount).toFixed(2)} DRK</b>
                </p>
              </>
            ) : (
              <div>
                <p className="mb-0 d-flex" style={{ visibility: 'hidden' }} aria-hidden={true}>
                  invisible placeholder
                </p>
                <label className="mb-0 mt-1 text-center">Connect to your wallet to stake</label>
              </div>
            )}
          </label>
          <input
            type="number"
            className="filter__input"
            style={{ minWidth: '300px' }}
            value={drkToStake}
            onChange={handleInputChange}
            data-testid="stake-input"
          />
          <div className="d-flex gap-3 mt-3">
            <button
              className="filter__btn px-2"
              style={{ maxHeight: '36px' }}
              type="button"
              onClick={(e) => setPercentageDrk(e, 25)}
            >
              25%
            </button>
            <button
              className="filter__btn px-2"
              style={{ maxHeight: '36px' }}
              type="button"
              onClick={(e) => setPercentageDrk(e, 50)}
            >
              50%
            </button>
            <button
              className="filter__btn px-2"
              style={{ maxHeight: '36px' }}
              type="button"
              onClick={(e) => setPercentageDrk(e, 75)}
            >
              75%
            </button>
            <button
              className="filter__btn px-2"
              style={{ maxHeight: '36px' }}
              type="button"
              onClick={(e) => setPercentageDrk(e, 100)}
            >
              100%
            </button>
          </div>
          <div className="filter__group" style={{ marginTop: '36px' }}>
            {Number(stakeData?.lockedAmount) > 0 && status === AUTHENTICATED_STATUS ? (
              <button className="filter__btn px-4" style={{ height: '46px' }} type="button" onClick={stake}>
                Edit
              </button>
            ) : (
              <button className="filter__btn px-4" style={{ height: '46px' }} type="button" onClick={stake}>
                Stake
              </button>
            )}
            {status === AUTHENTICATED_STATUS && stakeData?.claimedReward === null && (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="copied-tooltip" className="my-tooltip" style={{ position: 'fixed' }}>
                    <div>
                      Be sure to collect Your rewards by May 3, 2024, 6pm UTC.
                      <br />
                      Clicking this will unstake your DRK tokens, but feel free to participate in new staking program.
                    </div>
                  </Tooltip>
                }
              >
                <button
                  className="filter__btn py-2 px-3 text-uppercase item-owned mt-2"
                  style={{ height: '46px' }}
                  type="button"
                  onClick={claimRewards}
                >
                  Claim rewards
                </button>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
      {status === AUTHENTICATED_STATUS && stakeData?.claimedReward === null && (
        <p className="text-center mt-2 mb-0">
          Don&apos;t worry, if you choose not to unstake.
          <br /> Your tokens will automatically restake in the new pool.
        </p>
      )}
      <p
        className="text-white text-center mt-3 w-100 mb-0"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style={{ visibility: error ? 'visible' : 'hidden' }}
      >
        {error || 'error placeholder'}
      </p>
    </div>
  );
};

export { StakingForm };
