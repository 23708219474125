import 'cropperjs/dist/cropper.css';
import { createRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import { ErrorModal } from '../../../components/error-modal';
import { SuccessModal } from '../../../components/success-modal';
import { useStore } from '../../../store';
import { Fallback } from './fallback';
import { useUpdateProfile } from './utils';

const Settings = ({ usersData }) => {
  const {
    auth: { status },
  } = useStore();
  const [avatarImage, setAvatarImage] = useState(null);
  const avatarCropperRef = createRef();

  const [backgroundImage, setBackgroundImage] = useState(null);
  const backgroundCropperRef = createRef();

  const [name, setName] = useState('');
  const [postfix, setPostfix] = useState('');

  const updateProfileMutation = useUpdateProfile();

  const handleFileUpload = (e, setter) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setter(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const changeName = (e) => {
    setName(e.target.value);
  };

  const changePostfix = (e) => {
    setPostfix(e.target.value);
  };

  const confirmChange = () => {
    let profileImage;
    let background;
    if (typeof avatarCropperRef.current?.cropper !== 'undefined') {
      profileImage = avatarCropperRef.current?.cropper.getCroppedCanvas()?.toDataURL();
    }

    if (typeof backgroundCropperRef.current?.cropper !== 'undefined') {
      background = backgroundCropperRef.current?.cropper.getCroppedCanvas()?.toDataURL();
    }

    updateProfileMutation.mutate(
      {
        profileImage: profileImage || usersData?.profilePicture,
        backgroundImg: background || usersData?.backgroundImg,
        name: name || usersData?.name,
        profileUri: postfix || usersData?.profileUri,
      },
      {
        onSuccess: () => {
          setOpenSuccessModal(true);
        },
        onError: () => {
          setOpenErrorModal(true);
        },
      }
    );
  };

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  return status === 'authenticated' ? (
    <>
      <SuccessModal open={openSuccessModal} setOpen={setOpenSuccessModal}>
        <h4 className="sign__title" style={{ color: 'white', textAlign: 'center', fontWeight: 'normal' }}>
          Profile updated successfullyd
        </h4>
      </SuccessModal>
      <ErrorModal open={openErrorModal} setOpen={setOpenErrorModal}>
        <h4 className="sign__title" style={{ color: 'white', textAlign: 'center', fontWeight: 'normal' }}>
          Something went wrong.
          <br /> Please make sure provided data are correct.
        </h4>
      </ErrorModal>
      <div className="col-12 col-xl-9">
        <div className="main__title main__title--create">
          <h2>Update profile</h2>
        </div>
        <form action="#" className="sign__form sign__form--create">
          <div className="row">
            <div className="col-12">
              <h4 className="sign__title">Change avatar</h4>
            </div>
            <div className="col-12">
              <div className="sign__file">
                <label id="file1" htmlFor="sign__file-upload">
                  Upload avatar image
                </label>
                <input
                  data-name="#file1"
                  id="sign__file-upload"
                  name="file"
                  className="sign__file-upload"
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={(e) => handleFileUpload(e, setAvatarImage)}
                />
              </div>
            </div>
            <div>
              <Cropper
                ref={avatarCropperRef}
                style={{ height: 400, width: '100%', display: avatarImage ? 'block' : 'none' }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={avatarImage}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
              />
            </div>
            <div className="col-12 mt-4">
              <h4 className="sign__title">Change background</h4>
            </div>
            <div className="col-12">
              <div className="sign__file">
                <label id="file2" htmlFor="sign__file-upload2">
                  Upload background image
                </label>
                <input
                  data-name="#file2"
                  id="sign__file-upload2"
                  name="backgroundFile"
                  className="sign__file-upload"
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={(e) => handleFileUpload(e, setBackgroundImage)}
                />
              </div>
            </div>
            <div>
              <Cropper
                ref={backgroundCropperRef}
                style={{ height: 400, width: '100%', display: backgroundImage ? 'block' : 'none' }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={backgroundImage}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
              />
            </div>
            <div className="col-12 mt-4">
              <h4 className="sign__title">Change name</h4>
            </div>
            <div className="col-12">
              <div className="sign__group">
                <label className="sign__label visually-hidden" htmlFor="itemname">
                  change name
                </label>
                <input
                  id="itemname"
                  type="text"
                  name="itemname"
                  className="sign__input"
                  placeholder={usersData?.name}
                  value={name}
                  onChange={changeName}
                />
              </div>
            </div>
            <div className="col-12 mt-4">
              <h4 className="sign__title">Change custom profile postfix</h4>
            </div>
            <div className="col-12">
              <div className="sign__group">
                <label className="sign__label visually-hidden" htmlFor="customAddress">
                  Customize Your Profile Postfix
                </label>
                <input
                  id="customAddress"
                  type="text"
                  name="customAddress"
                  className="sign__input"
                  placeholder={usersData?.profileUri || ''}
                  value={postfix}
                  onChange={changePostfix}
                />
                <p className="text-white mt-2">
                  Your profile will be available under market.dark.city/shop/__POSTFIX__
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="row d-flex align-items-center my-3">
                <div className="col-6">
                  <button type="button" className="btn btn-primary sign__btn m-0" onClick={confirmChange}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  ) : (
    <Fallback text="Connect to your wallet to view settings..." />
  );
};

export { Settings };
