import { useQuery } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const fetchData = async (url) => {
  const response = await fetch(url, {
    credentials: 'include',
  });

  if (response.status === 204) {
    return [];
  }

  try {
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error parsing JSON response: ${error.message}`);
    return null;
  }
};

const useGetOrders = () => {
  const url = `${BASE_URL}/orders`;

  const users = useQuery({
    queryKey: ['orders'],
    queryFn: () => fetchData(url),
  });

  return users;
};
export { useGetOrders };
