import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const useListItem = (itemId) => {
  const queryClient = useQueryClient();

  const listItemMutation = useMutation({
    mutationKey: ['listItem'],
    mutationFn: async (price) => {
      const body = { price: parseFloat(price, 10) };

      const response = await fetch(`${BASE_URL}/items/${itemId}/list`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Failed to list item');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['offchainItems']);
      queryClient.invalidateQueries(['inventory']);
    },
  });

  return listItemMutation;
};

export { useListItem };
