import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Header } from '../../components/header';
import { fetchData } from '../all-items/utils';
import { Fallback } from './Fallback';
import { BASE_URL } from '../../../config';
import logo from './assets/dark_city_logo.jpg';
import { convertTimestamp } from '../your-inventory/my-orders';

const TransferDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const { data, error, isLoading } = useQuery({
    queryKey: ['transfer', orderId],
    queryFn: () => fetchData(`${BASE_URL}/transfers/details/${orderId}`),
  });

  if (isLoading) return <Fallback text="Loading items..." />;
  if (error) return <Fallback text="Error while fetching data..." />;

  const { from, to, at, type, itemId, name, itemImg, amount } = data;

  const imagePath = itemImg ? `https://darkcity.infura-ipfs.io/ipfs/${itemImg}` : logo;

  const TokenTransferDetails = () => {
    return (
      <>
        <div className="row row--grid">
          {/* breadcrumb */}
          <div className="col-12">
            <ul className="breadcrumb">
              <li className="breadcrumb__item">
                <Link onClick={() => navigate(-1)}>Inventory</Link>
              </li>
              <li className="breadcrumb__item breadcrumb__item--active">{orderId}</li>
            </ul>
          </div>
          {/* end breadcrumb */}
          <div className="col-12">
            <div className="main__title main__title--page">
              <h1 className="fs-2">DRK token transfer</h1>
            </div>
          </div>
        </div>
        <div className="row">
          {/* content */}
          <div className="col-12 col-xl-8">
            <div className="asset__item">
              <img src={imagePath} alt="" />

              {/* share */}
              <div className="share share--asset">
                <button
                  className="share__link share__link--link"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                  }}
                >
                  <svg width={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M8,12a1,1,0,0,0,1,1h6a1,1,0,0,0,0-2H9A1,1,0,0,0,8,12Zm2,3H7A3,3,0,0,1,7,9h3a1,1,0,0,0,0-2H7A5,5,0,0,0,7,17h3a1,1,0,0,0,0-2Zm7-8H14a1,1,0,0,0,0,2h3a3,3,0,0,1,0,6H14a1,1,0,0,0,0,2h3A5,5,0,0,0,17,7Z" />
                  </svg>
                  <span>copy link</span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4">
            <div className="asset__info">
              <div className="asset__desc">
                <h2>{itemId}</h2>
              </div>
              <ul className="asset__authors">
                <li className="justify-content-between align-items-center flex-row" style={{ width: '100%' }}>
                  <div>
                    <p style={{ color: '#bdbdbd', fontSize: '12px' }}>Amount</p>
                    <p className="mt-2 column">{amount} DRK</p>
                  </div>
                </li>
              </ul>
              <ul className="asset__authors">
                <li className="justify-content-between align-items-center flex-row" style={{ width: '100%' }}>
                  <div>
                    <p style={{ color: '#bdbdbd', fontSize: '12px' }}>From</p>
                    <p className="mt-2 column">{from}</p>
                  </div>
                </li>
              </ul>
              <ul className="asset__authors">
                <li className="justify-content-between align-items-center flex-row" style={{ width: '100%' }}>
                  <div>
                    <p style={{ color: '#bdbdbd', fontSize: '12px' }}>To</p>
                    <p className="mt-2 column">{to}</p>
                  </div>
                </li>
              </ul>
              <ul className="asset__authors">
                <li className="justify-content-between align-items-center flex-row" style={{ width: '100%' }}>
                  <div>
                    <p style={{ color: '#bdbdbd', fontSize: '12px' }}>At</p>
                    <p className="mt-2 column">{convertTimestamp(at)}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* end sidebar */}
        </div>
      </>
    );
  };

  const ItemTransferDetails = () => {
    return (
      <>
        <div className="row row--grid">
          {/* breadcrumb */}
          <div className="col-12">
            <ul className="breadcrumb">
              <li className="breadcrumb__item">
                <Link onClick={() => navigate(-1)}>Inventory</Link>
              </li>
              <li className="breadcrumb__item breadcrumb__item--active">{orderId}</li>
            </ul>
          </div>
          {/* end breadcrumb */}
          <div className="col-12">
            <div className="main__title main__title--page">
              <h1 className="fs-2">{name}</h1>
            </div>
          </div>
        </div>
        <div className="row">
          {/* content */}
          <div className="col-12 col-xl-8">
            <div className="asset__item">
              <img src={imagePath} alt="" />

              {/* share */}
              <div className="share share--asset">
                <button
                  className="share__link share__link--link"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                  }}
                >
                  <svg width={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M8,12a1,1,0,0,0,1,1h6a1,1,0,0,0,0-2H9A1,1,0,0,0,8,12Zm2,3H7A3,3,0,0,1,7,9h3a1,1,0,0,0,0-2H7A5,5,0,0,0,7,17h3a1,1,0,0,0,0-2Zm7-8H14a1,1,0,0,0,0,2h3a3,3,0,0,1,0,6H14a1,1,0,0,0,0,2h3A5,5,0,0,0,17,7Z" />
                  </svg>
                  <span>copy link</span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4">
            <div className="asset__info">
              <ul className="asset__authors">
                <li className="justify-content-between align-items-center flex-row" style={{ width: '100%' }}>
                  <div>
                    <p style={{ color: '#bdbdbd', fontSize: '12px' }}>Item ID</p>
                    <p className="mt-1 column">{itemId}</p>
                  </div>
                </li>
              </ul>
              <ul className="asset__authors">
                <li className="justify-content-between align-items-center flex-row" style={{ width: '100%' }}>
                  <div>
                    <p style={{ color: '#bdbdbd', fontSize: '12px' }}>From</p>
                    <p className="mt-1 column">{from}</p>
                  </div>
                </li>
              </ul>
              <ul className="asset__authors">
                <li className="justify-content-between align-items-center flex-row" style={{ width: '100%' }}>
                  <div>
                    <p style={{ color: '#bdbdbd', fontSize: '12px' }}>To</p>
                    <p className="mt-1 column">{to}</p>
                  </div>
                </li>
              </ul>
              <ul className="asset__authors">
                <li className="justify-content-between align-items-center flex-row" style={{ width: '100%' }}>
                  <div>
                    <p style={{ color: '#bdbdbd', fontSize: '12px' }}>At</p>
                    <p className="mt-1 column">{convertTimestamp(at)}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* end sidebar */}
        </div>
      </>
    );
  };

  const displayCorrectDetails = () => {
    if (type === 'token') return <TokenTransferDetails />;
    if (type === 'item') return <ItemTransferDetails />;
  };

  return (
    <>
      <Header />
      <main className="main" style={{ minHeight: '92dvh' }}>
        <div className="container">{displayCorrectDetails()}</div>
      </main>
    </>
  );
};

export default TransferDetails;
