import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { ConvertModal } from '../../components/convert-modal';
import { Header, formatBitcoinAddress } from '../../components/header';
import { TransferModal } from '../../components/transfer-modal';
import { useStore } from '../../store';
import avatar from './assets/avatar5.jpg';
import background from './assets/background.jpg';
import { MyOrders } from './my-orders';
import { OfficialItems } from './official-items';
import { Settings } from './settings';
import { UnofficialItems } from './unofficial-items';
import { useGetUsers } from './useGetUsers';
import { useTransferDrk } from './unofficial-items/useTransferDrk';
import { PlaceholderBackground } from '../../components/placeholder-background';
import { GiftDrkModal } from '../../components/gift-drk-modal';
import { MyListings } from './listings';
import { CopyToClipboardButton } from '../../components/copy-to-clipboard-button';

const YourInventory = () => {
  const {
    auth: { address, status },
  } = useStore();
  const { pathname } = useLocation();

  const [disablePointerEvents, setDisablePointerEvents] = useState(false);

  // to prevent displaying two tabs, when we click fast
  const handleClick = () => {
    setDisablePointerEvents(true);

    setTimeout(() => {
      setDisablePointerEvents(false);
    }, 400);
  };

  const result = useGetUsers();
  const { data: usersData, isLoading } = result;

  const [searchingPhrase, setSearchingPhrase] = useState('');

  const isNotMintedTabActive = pathname.includes('your-inventory/not-minted');
  const isMintedTabActive = pathname.includes('your-inventory/minted');
  const isOrdersTabActive = pathname.includes('your-inventory/orders');
  const isSettingsTabActive = pathname.includes('your-inventory/settings');
  const isListingTabActive = pathname.includes('your-inventory/listings');

  const [open, setOpen] = useState(false);
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const transferDrkMutation = useTransferDrk();
  const [data, setData] = useState(null);

  const [giftDialogOpen, setGiftDialogOpen] = useState(false);

  const openConvertDialog = () => {
    setOpen(true);
  };

  const openGiftDialog = () => {
    setGiftDialogOpen(true);
  };

  const openTransferDialog = () => {
    transferDrkMutation.mutate(
      {},
      {
        onSuccess: (data) => {
          setData(data);
        },
      }
    );
    setTransferModalOpen(true);
  };

  return (
    <>
      <GiftDrkModal open={giftDialogOpen} setOpen={setGiftDialogOpen} usersData={usersData} />
      <ConvertModal open={open} setOpen={setOpen} usersData={usersData} />
      <TransferModal
        open={transferModalOpen}
        setOpen={setTransferModalOpen}
        data={data}
        isLoading={transferDrkMutation.isPending}
      />
      <Header searchingPhrase={searchingPhrase} setSearchingPhrase={setSearchingPhrase} />
      <main className="main" style={{ minHeight: '105dvh' }}>
        {isLoading ? (
          <PlaceholderBackground />
        ) : (
          <img
            className="main__author"
            src={status === 'authenticated' && usersData?.backgroundImg ? usersData?.backgroundImg : background}
            alt=""
          />
        )}
        <div className="container">
          <div className="row row--grid">
            {
              <>
                <div className="col-12 col-xl-3">
                  <div className="author author--page">
                    <div className="author__meta">
                      <div className="author__avatar author__avatar--verified">
                        <img
                          src={
                            status === 'authenticated' && usersData?.profilePicture ? usersData?.profilePicture : avatar
                          }
                          alt=""
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      </div>
                      <h1 className="author__name mb-2" aria-label="username">
                        <a>{status === 'authenticated' && usersData?.name ? usersData?.name : 'Username'}</a>
                      </h1>
                      {status === 'authenticated' && (
                        <div className="d-flex gap-2 align-items-center" style={{ lineHeight: '22px' }}>
                          <p className="profile__paragraph mb-0">{formatBitcoinAddress(address)}</p>
                          <CopyToClipboardButton textToCopy={address} />
                        </div>
                      )}

                      {status === 'authenticated' && (
                        <>
                          <div
                            className="d-flex profile__paragraph align-items-center mt-3 fs-7 justify-content-between"
                            style={{ lineHeight: '22px' }}
                          >
                            <span className="mt-0">
                              {usersData?.offChainDRK ? (usersData?.offChainDRK / 10 ** 18).toFixed(2) : (0).toFixed(2)}{' '}
                              DRK{' '}
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="credits-tooltip">
                                    Amount you can spend for transactions in Dark-city
                                  </Tooltip>
                                }
                              >
                                <b className="text-white">credits</b>
                              </OverlayTrigger>
                            </span>
                            <OverlayTrigger
                              placement="left"
                              overlay={<Tooltip id="gift-drk-tooltip">Gift DRK credits</Tooltip>}
                            >
                              <button onClick={openGiftDialog} aria-label="gift drk credits">
                                <svg
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 7V20M12 7H8.46429C7.94332 7 7.4437 6.78929 7.07533 6.41421C6.70695 6.03914 6.5 5.53043 6.5 5C6.5 4.46957 6.70695 3.96086 7.07533 3.58579C7.4437 3.21071 7.94332 3 8.46429 3C11.2143 3 12 7 12 7ZM12 7H15.5357C16.0567 7 16.5563 6.78929 16.9247 6.41421C17.293 6.03914 17.5 5.53043 17.5 5C17.5 4.46957 17.293 3.96086 16.9247 3.58579C16.5563 3.21071 16.0567 3 15.5357 3C12.7857 3 12 7 12 7ZM5 12H19V17.8C19 18.9201 19 19.4802 18.782 19.908C18.5903 20.2843 18.2843 20.5903 17.908 20.782C17.4802 21 16.9201 21 15.8 21H8.2C7.07989 21 6.51984 21 6.09202 20.782C5.71569 20.5903 5.40973 20.2843 5.21799 19.908C5 19.4802 5 18.9201 5 17.8V12ZM4.6 12H19.4C19.9601 12 20.2401 12 20.454 11.891C20.6422 11.7951 20.7951 11.6422 20.891 11.454C21 11.2401 21 10.9601 21 10.4V8.6C21 8.03995 21 7.75992 20.891 7.54601C20.7951 7.35785 20.6422 7.20487 20.454 7.10899C20.2401 7 19.9601 7 19.4 7H4.6C4.03995 7 3.75992 7 3.54601 7.10899C3.35785 7.20487 3.20487 7.35785 3.10899 7.54601C3 7.75992 3 8.03995 3 8.6V10.4C3 10.9601 3 11.2401 3.10899 11.454C3.20487 11.6422 3.35785 11.7951 3.54601 11.891C3.75992 12 4.03995 12 4.6 12Z"
                                    stroke="#6164ff"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </OverlayTrigger>
                          </div>
                          <div
                            className="d-flex profile__paragraph align-items-center mt-2 fs-7 justify-content-between"
                            style={{ lineHeight: '22px' }}
                          >
                            <span className="mt-0">
                              {usersData?.transferableDRK
                                ? (usersData?.transferableDRK / 10 ** 18).toFixed(2)
                                : (0).toFixed(2)}{' '}
                              DRK{' '}
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="transferable-tooltip">
                                    Amount you can transfer from your wallet to Dark-city
                                  </Tooltip>
                                }
                              >
                                <b className="text-white">transferable</b>
                              </OverlayTrigger>
                            </span>
                            <OverlayTrigger
                              placement="left"
                              overlay={<Tooltip id="convert-tooltip">Convert to DRK credits</Tooltip>}
                            >
                              <button onClick={openTransferDialog} aria-label="Convert to DRK credits">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width={20}
                                  height={20}
                                  viewBox="0 0 50 50"
                                  style={{ fill: '#6164ff' }}
                                >
                                  <path d="M 20 4 C 14.507813 4 10 8.507813 10 14 L 10 31.75 L 7.125 28.875 L 4.3125 31.71875 L 12 39.40625 L 19.6875 31.71875 L 16.875 28.90625 L 14 31.75 L 14 14 C 14 10.691406 16.691406 8 20 8 L 31 8 L 31 4 Z M 38 10.59375 L 30.28125 18.3125 L 33.125 21.125 L 36 18.25 L 36 36 C 36 39.308594 33.308594 42 30 42 L 19 42 L 19 46 L 30 46 C 35.492188 46 40 41.492188 40 36 L 40 18.25 L 42.875 21.125 L 45.6875 18.28125 Z" />
                                </svg>
                              </button>
                            </OverlayTrigger>
                          </div>
                          <div
                            className="d-flex profile__paragraph align-items-center mt-2 fs-7 justify-content-between"
                            style={{ lineHeight: '22px' }}
                          >
                            <span className="mt-0">
                              {usersData?.balanceDRK ? (usersData?.balanceDRK / 10 ** 18).toFixed(2) : (0).toFixed(2)}{' '}
                              DRK <span>balance</span>
                            </span>
                            <OverlayTrigger
                              placement="left"
                              overlay={<Tooltip id="mint-transfer-tooltip">Mint transfer</Tooltip>}
                            >
                              <button onClick={openConvertDialog} aria-label="Mint transfer">
                                <svg width={20} height={20} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3.02172 1.79241C3.11977 1.33042 3.52766 1 3.99994 1H7.49994C10.8167 1 13.9504 2.15662 16.5142 4.05499L16.8139 3.77241C17.2074 3.40139 17.8246 3.41046 18.207 3.79289L20.207 5.79289C20.5895 6.17532 20.5986 6.79251 20.2275 7.18601L19.945 7.48571C21.8433 10.0496 22.9999 13.1833 22.9999 16.5V20C22.9999 20.4723 22.6695 20.8802 22.2075 20.9782C21.7455 21.0763 21.2779 20.8377 21.0861 20.4061C19.5548 16.9607 17.8884 14.1436 15.9191 11.7594L5.78873 22.5551C4.82264 23.5847 3.19624 23.6105 2.19791 22.6122L1.38775 21.802C0.389428 20.8037 0.415275 19.1773 1.44482 18.2112L12.2405 8.08084C9.85633 6.11151 7.03927 4.44513 3.5938 2.91381C3.16223 2.722 2.92368 2.2544 3.02172 1.79241ZM13.7415 9.41504L2.81338 19.6696C2.60747 19.8629 2.6023 20.1881 2.80197 20.3878L3.61213 21.198C3.81179 21.3976 4.13707 21.3925 4.33029 21.1866L14.5849 10.2585C14.3099 9.97106 14.0289 9.69005 13.7415 9.41504ZM8.31869 3.02736C10.6049 4.30007 12.6125 5.70113 14.4033 7.30303C15.2115 8.026 15.9739 8.78848 16.6969 9.59669C18.2988 11.3875 19.6999 13.3951 20.9726 15.6813C20.7848 12.8712 19.6408 10.2169 17.8574 8.06282C17.2794 7.36462 16.6353 6.72057 15.9371 6.14252C13.783 4.35913 11.1287 3.21514 8.31869 3.02736Z"
                                    fill="#6164ff"
                                  />
                                </svg>
                              </button>
                            </OverlayTrigger>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xl-9">
                  <div className="profile">
                    {/* tabs nav */}
                    <ul
                      className="nav nav-tabs profile__tabs"
                      id="profile__tabs"
                      role="tablist"
                      style={{ pointerEvents: disablePointerEvents ? 'none' : 'auto' }}
                    >
                      <li className="nav-item">
                        <a
                          className={isNotMintedTabActive ? 'active' : ''}
                          aria-selected={isNotMintedTabActive ? 'true' : 'false'}
                          data-toggle="tab"
                          href="#tab-1"
                          role="tab"
                          aria-controls="tab-1"
                          onClick={handleClick}
                        >
                          <Link to="/your-inventory/not-minted/grouped/1" style={{ color: 'inherit' }}>
                            Off-chain items
                          </Link>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={isMintedTabActive ? 'active' : ''}
                          aria-selected={isMintedTabActive ? 'true' : 'false'}
                          data-toggle="tab"
                          href="#tab-2"
                          role="tab"
                          aria-controls="tab-2"
                          onClick={handleClick}
                        >
                          <Link to="/your-inventory/minted/1" style={{ color: 'inherit' }}>
                            On-chain items
                          </Link>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={isOrdersTabActive ? 'active' : ''}
                          aria-selected={isOrdersTabActive ? 'true' : 'false'}
                          data-toggle="tab"
                          href="#tab-3"
                          role="tab"
                          aria-controls="tab-3"
                          onClick={handleClick}
                        >
                          <Link to="/your-inventory/orders" style={{ color: 'inherit' }}>
                            My orders
                          </Link>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={isListingTabActive ? 'active' : ''}
                          aria-selected={isListingTabActive ? 'true' : 'false'}
                          data-toggle="tab"
                          href="#tab-4"
                          role="tab"
                          aria-controls="tab-4"
                          onClick={handleClick}
                        >
                          <Link to="/your-inventory/listings" style={{ color: 'inherit' }}>
                            My listings
                          </Link>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={isSettingsTabActive ? 'active' : ''}
                          aria-selected={isSettingsTabActive ? 'true' : 'false'}
                          data-toggle="tab"
                          href="#tab-5"
                          role="tab"
                          aria-controls="tab-5"
                          onClick={handleClick}
                        >
                          <Link to="/your-inventory/settings" style={{ color: 'inherit' }}>
                            Settings
                          </Link>
                        </a>
                      </li>
                    </ul>
                    {/* end tabs nav */}
                  </div>
                  {/* content tabs */}
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade${isNotMintedTabActive ? ' show active fade-in' : ''}`}
                      id="tab-1"
                      role="tabpanel"
                    >
                      <UnofficialItems />
                    </div>
                    <div
                      className={`tab-pane fade${isMintedTabActive ? ' show active fade-in' : ''}`}
                      id="tab-2"
                      role="tabpanel"
                    >
                      <OfficialItems searchingPhrase={searchingPhrase} />
                    </div>
                    <div
                      className={`tab-pane fade${isOrdersTabActive ? ' show active fade-in' : ''}`}
                      id="tab-3"
                      role="tabpanel"
                    >
                      <div className="row">
                        <MyOrders />
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade${isListingTabActive ? ' show active fade-in' : ''}`}
                      id="tab-4"
                      role="tabpanel"
                    >
                      <div className="row">
                        <MyListings usersData={usersData} />
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade${isSettingsTabActive ? ' show active fade-in' : ''}`}
                      id="tab-5"
                      role="tabpanel"
                    >
                      <div className="row row--grid">
                        <Settings usersData={usersData} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content tabs */}
              </>
            }
          </div>
        </div>
      </main>
    </>
  );
};

export { YourInventory };
