import { Zoom } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { useEffect } from 'react';

import { useUnisat } from './hooks/useUnisat';
import unisatIcon from './icons/unisat_logo.png';
import xverseIcon from './icons/xverse.png';
import { useUnisatAuth } from '../../auth/unisat';
import { UseXverse } from './hooks/useXverse';
import { useXverseAuth } from '../../auth/xverse';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const ConnectWalletModal = ({ handleClickOpen, handleClose, open }) => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const { connected, unisatInstalled, handleAccountsChanged } = useUnisat();
  const { ordinalsAddress, ordinalsPublicKey, onConnectClick, capabilityState } = UseXverse();

  const unisat = window.unisat;
  const xverseInstalled = capabilityState !== 'missing';

  const unisatAuthMutation = useUnisatAuth();
  const xverseAuthMutation = useXverseAuth();

  const handleClick = async () => {
    handleClose();
    if (!unisatInstalled) {
      window.open('https://unisat.io', '_blank', 'noopener,noreferrer');
    }

    let result;
    try {
      result = await unisat.requestAccounts();
    } catch (error) {
      if (!unisatInstalled) {
        setErrorMessage('Please install wallet extension and refresh page');
      } else {
        setErrorMessage('Please create account inside chosen wallet and log in');
      }
      handleClickOpen();
      return;
    }

    setErrorMessage('');
    handleAccountsChanged(result);

    await new Promise((resolve) => setTimeout(resolve, 1000));
    const publicKey = await unisat.getPublicKey();

    if (!publicKey || !result[0]) {
      setErrorMessage('Something went wrong. Please try again');
      handleClickOpen();
      return;
    }

    setErrorMessage('');
    unisatAuthMutation.mutate(
      { publicKey, address: result[0] },
      {
        onError: () => {
          setErrorMessage('Something went wrong. Please try again');
          handleClickOpen();
        },
      }
    );
  };

  const handleXverseClick = async () => {
    handleClose();
    if (!xverseInstalled) {
      window.open('https://www.xverse.app/download', '_blank', 'noopener,noreferrer');
      return;
    }
    await onConnectClick();

    await new Promise((resolve) => setTimeout(resolve, 1000));
    xverseAuthMutation.mutate({ publicKey: ordinalsPublicKey, address: ordinalsAddress });
  };

  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
        }}
        PaperProps={{ style: { backgroundColor: '#16151a', padding: '40px 40px 25px 40px', maxWidth: '420px' } }}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <button className="modal__close" type="button" style={{ top: '37px', right: '37px' }} onClick={handleClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
          </svg>
        </button>
        <h4 className="sign__title">Connect Wallet</h4>
        <p className="modal__text">Choose one of available wallet providers or create a new wallet.</p>
        <ul>
          <li>
            <button
              className="modal__wallet"
              // from example: https://github.com/unisat-wallet/unisat-web3-demo/blob/master/src/App.tsx#L67
              onClick={handleClick}
            >
              <img src={unisatIcon} alt="" />
              <span>Unisat</span>
            </button>
          </li>
          <li>
            <button className="modal__wallet" onClick={handleXverseClick}>
              <img src={xverseIcon} alt="" />
              <span>Xverse</span>
            </button>
          </li>
        </ul>
        <div className="text-center text-white mt-2">{errorMessage}</div>
      </Dialog>
    </>
  );
};

export { ConnectWalletModal };
