/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CountUp from 'react-countup';
import dark_city_icon from '../../assets/images/icon/dark_city_icon_small.png';
import nor_icon from '../../assets/images/icon/nor_icon_small.png';
import { UseXverse } from '../../components/connect-wallet-modal/hooks/useXverse';
import { Header } from '../../components/header';

import { StakingProgressModal } from '../../components/staking-progress-modal';
import { SuccessModal } from '../../components/success-modal';
import { AUTHENTICATED_STATUS, NOT_AUTHENTICATED_STATUS, useStore } from '../../store';
import { convertTimestamp } from '../your-inventory/my-orders';
import { useGetUsers } from '../your-inventory/useGetUsers';
import { useStakeData } from './useStakeData';
import { useStakeHistory } from './useStakeHistory';
import { useStakeUnisat } from './useStakeUnisat';
import { useStakeXverse } from './useStakeXverse';

import { ErrorModal } from '../../components/error-modal';
import { QuizModal } from './quiz-modal';
import SignTransaction from '../../components/sign-transaction-xverse';
import { QUIZ_NOT_DONE, useQuizData } from './useQuizData';
import { StakingForm } from './staking-form';

export const COUNT_UP_DELAY = 2;

const Stake = () => {
  const {
    auth: { status },
  } = useStore();

  const backgroundColor = 'transparent';

  const result = useGetUsers();
  const { data: usersData, isLoading: usersDataLoading, isFetching } = result;

  const quizDataResult = useQuizData();
  const { data: quizData } = quizDataResult;

  const stakeDataResult = useStakeData();
  const { data: stakeData } = stakeDataResult;

  const [drkToStake, setDrkToStake] = useState('');

  const stakeHistoryResult = useStakeHistory();
  const { data: stakeHistoryData } = stakeHistoryResult;

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [stakingProgressModalOpen, setStakingProgressModalOpen] = useState(false);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [claimRewardsDialogOpen, setClaimRewardsDialogOpen] = useState(false);

  // After data fetched, display modals with progress of staking or error, if balance is not sufficient
  useEffect(() => {
    const balance = Number(usersData?.balanceDRK);
    const transferableBalance = Number(usersData?.transferableDRK);
    const balanceAvailableForStaking = Math.abs((balance - transferableBalance) / 10 ** 18);

    const currentStakedAmount = parseFloat(stakeData?.lockedAmount);
    const notEnoughBalanceForStaking = balanceAvailableForStaking < currentStakedAmount;

    const shouldDisplayErrorModal =
      stakeData && notEnoughBalanceForStaking && status === AUTHENTICATED_STATUS && !isFetching;

    if (shouldDisplayErrorModal) {
      setErrorModalOpen(true);
    }

    const shouldDisplayStakingProgressModal =
      stakeData &&
      usersData &&
      stakeData?.estimatedCurrentReward > 0 &&
      !notEnoughBalanceForStaking &&
      status === AUTHENTICATED_STATUS &&
      !openSuccessModal &&
      !claimRewardsDialogOpen &&
      !isFetching;

    if (shouldDisplayStakingProgressModal) {
      setStakingProgressModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakeData, usersData, status, isFetching]);

  // populate input data with currently staked amount
  useEffect(() => {
    if (status === NOT_AUTHENTICATED_STATUS) {
      setDrkToStake('');
    }

    if (stakeData && status === AUTHENTICATED_STATUS && !isFetching) {
      setDrkToStake(Number(stakeData?.lockedAmount).toFixed(2));
    }
  }, [stakeData, status, isFetching]);

  return (
    <div className="home-8">
      <SuccessModal open={claimRewardsDialogOpen} setOpen={setClaimRewardsDialogOpen}>
        <h4
          className="sign__title mb-0"
          style={{ color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '22px' }}
        >
          {stakeData?.claimedReward} DRK was claimed successfully
        </h4>
        <p className="text-white">You can expect your rewards soon!</p>
      </SuccessModal>
      <ErrorModal open={errorModalOpen} setOpen={setErrorModalOpen}>
        <h4 className="sign__title" style={{ color: 'white', textAlign: 'center', fontWeight: 'normal' }}>
          Your current DRK balance is smaller than staking amount
          <p className="mb-0 mt-1">You were removed from staking program</p>
        </h4>
      </ErrorModal>
      <QuizModal open={quizModalOpen} setOpen={setQuizModalOpen} />
      <StakingProgressModal open={stakingProgressModalOpen} setOpen={setStakingProgressModalOpen}>
        <div>
          <h4 className="sign__title mb-0 fs-5 text-center" style={{ color: 'white', fontWeight: 'normal' }}>
            Your estimated staking reward is growing
          </h4>
          <p className="text-white mt-2 text-center mb-0">
            You currently earned {stakeData?.estimatedCurrentReward}{' '}
            <img
              src={dark_city_icon}
              alt=""
              className="rounded-circle"
              height="20px"
              width="20px"
              style={{ marginTop: '-2px' }}
            />
          </p>
        </div>
      </StakingProgressModal>
      <SuccessModal open={openSuccessModal} setOpen={setOpenSuccessModal}>
        <h4 className="sign__title" style={{ color: 'white', fontWeight: 'normal' }}>
          DRK staked successfully
        </h4>
      </SuccessModal>
      <Header />
      <main className="main" style={{ minHeight: 'calc(84.7dvh - 70px)', marginTop: '140px' }}>
        <div className="container">
          <div className="row">
            {/* Tokens locked card */}
            <div className="col-lg-4 col-sm-6">
              <div className="card text-white rounded-3" style={{ backgroundColor: backgroundColor }}>
                <div className="card-body">
                  <p className="card-title fs-3">
                    {stakeData?.totalValueLocked ? (
                      <CountUp end={stakeData?.totalValueLocked || '0'} duration={COUNT_UP_DELAY} />
                    ) : (
                      'Loading...'
                    )}
                  </p>
                  <p className="card-text fs-6">
                    Total{' '}
                    <img
                      src={dark_city_icon}
                      alt=""
                      className="rounded-circle"
                      height="24px"
                      width="24px"
                      style={{ marginTop: '-3px' }}
                    />{' '}
                    tokens locked
                  </p>
                </div>
              </div>
            </div>

            {/* Estimated reward card */}
            <div className="col-lg-4 col-sm-6">
              <div className="card text-white rounded-3" style={{ backgroundColor: backgroundColor }}>
                <div className="card-body">
                  {stakeData?.estimatedTotalReward &&
                  status === AUTHENTICATED_STATUS &&
                  Number(stakeData?.lockedAmount) > 0 ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          id="stakingBalance"
                          style={{ position: 'fixed', visibility: stakeHistoryData?.length > 0 ? 'visible' : 'hidden' }}
                        >
                          {stakeHistoryData &&
                            stakeHistoryData
                              .slice()
                              .reverse()
                              .map((item, index) => (
                                <p key={index} className="m-0 mb-1">
                                  {item.amount} DRK staked from {convertTimestamp(item.at)}
                                </p>
                              ))}
                        </Tooltip>
                      }
                    >
                      <div>
                        <p className="card-title fs-3">
                          <CountUp
                            end={stakeData?.estimatedTotalReward || '0'}
                            decimals={2}
                            duration={COUNT_UP_DELAY}
                          />{' '}
                          <img
                            src={dark_city_icon}
                            alt=""
                            className="rounded-circle"
                            height="30px"
                            width="30px"
                            style={{ marginTop: '-4px', marginRight: '8px' }}
                          />
                          +
                          <CountUp
                            end={stakeData?.estimatedTotalReward / 6 || '0'}
                            decimals={2}
                            duration={COUNT_UP_DELAY}
                            style={{ marginLeft: '8px' }}
                          />{' '}
                          <img
                            src={nor_icon}
                            alt=""
                            className="rounded-circle"
                            height="30px"
                            width="30px"
                            style={{ marginTop: '-4px' }}
                          />
                        </p>
                        <p className="card-text fs-6">Your estimated reward</p>
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <>
                      <p className="card-title fs-3">Stake to see reward</p>
                      <p className="card-text fs-6">Estimated reward can change dynamically</p>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Number of stakers card*/}
            <div className="col-lg-4 col-sm-6">
              <div className="card text-white rounded-3" style={{ backgroundColor: backgroundColor }}>
                <div className="card-body">
                  <p className="card-title fs-3">
                    {stakeData?.estimatedOneTokenReward ? (
                      <CountUp
                        end={stakeData?.estimatedOneTokenReward * 100 || '0'}
                        duration={COUNT_UP_DELAY}
                        suffix="%"
                      />
                    ) : (
                      'Loading...'
                    )}
                  </p>
                  <p className="card-text fs-6">Reward per every DRK staked</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-white staking-description rounded-3"
            style={{ backgroundColor: backgroundColor, border: '1px solid #222227' }}
          >
            <div className="row w-100" style={{ padding: '40px 20px 20px' }}>
              <div className="col-xl-8">
                {/* Staking description */}
                <h1 className="fs-3">Rules and instructions</h1>
                <h2 className="fs-6 responsive-width" style={{ marginTop: '24px' }}>
                  <p>
                    Staking requires an amount of DRK tokens as{' '}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="availableTooltip1" style={{ position: 'fixed' }}>
                          DRK tokens available for staking, calculated as your current DRK balance minus transferable
                          DRK.
                        </Tooltip>
                      }
                    >
                      <b style={{ color: '#6164ff', cursor: 'pointer' }}>available</b>
                    </OverlayTrigger>{' '}
                    balance.
                  </p>

                  <p>
                    Failure to hold the staked amount in your wallet during the lock period will result in automatic
                    removal from the staking program.
                  </p>
                  <p>
                    Staked amount should be{' '}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="availableTooltip2" style={{ position: 'fixed' }}>
                          DRK tokens available for staking, calculated as your current DRK balance minus transferable
                          DRK.
                        </Tooltip>
                      }
                    >
                      <b style={{ color: '#6164ff', cursor: 'pointer' }}>available</b>
                    </OverlayTrigger>{' '}
                    balance during the lock period, if you inscribe transfers your staking might be deactivated.
                  </p>
                  <p>
                    To cancel your{' '}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="transferableTooltip1" style={{ position: 'fixed' }}>
                          Amount you can transfer from your wallet to Dark-city.
                        </Tooltip>
                      }
                    >
                      <b style={{ color: '#6164ff', cursor: 'pointer' }}>transferable</b>
                    </OverlayTrigger>{' '}
                    balance in order to stake, just send the transfer inscription(s) to your own wallet.
                  </p>
                  <p>
                    The calculation of staking rewards is dynamic and depends on the number of participants in the
                    program.
                  </p>
                  <p>
                    Rewards are calculated per staking period (number of days with staked DRK before the end of the
                    staking period), so the earlier you stake, bigger are the rewards.
                  </p>
                  <div className="d-flex justify-content-between align-items-start gap-4">
                    <div>
                      <div className="mb-0" style={{ maxWidth: '45ch' }}>
                        <p className="mb-0">
                          Every 30 days, you will have to complete a short quiz to keep your stake. You will need to
                          perform this task in order to remain part of the staking program.
                        </p>

                        <p
                          className="mb-0"
                          style={{
                            visibility:
                              stakeData?.stakedDays && status === AUTHENTICATED_STATUS && quizData === QUIZ_NOT_DONE
                                ? 'visible'
                                : 'hidden',
                          }}
                        >
                          You have<b style={{ color: '#6164ff' }}> {30 - Number(stakeData?.stakedDays)} days</b> left.
                        </p>
                      </div>
                    </div>
                    {stakeData?.lockedAmount && status === AUTHENTICATED_STATUS && (
                      <div>
                        <div className="d-flex">
                          <button
                            className="filter__btn px-4"
                            type="button"
                            onClick={() => setQuizModalOpen(true)}
                            disabled={quizData !== QUIZ_NOT_DONE}
                            style={{
                              height: '46px',
                            }}
                          >
                            {quizData !== QUIZ_NOT_DONE ? 'Quiz completed' : 'Complete quiz'}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </h2>
                <div>
                  <p className="mb-0 mt-2">
                    <>Staking ends on June 26, 2024, 6pm UTC</>
                  </p>
                </div>
              </div>
              <div className="col-xl-4 margin-resp">
                <StakingForm
                  usersData={usersData}
                  usersDataLoading={usersDataLoading}
                  stakeData={stakeData}
                  setOpenSuccessModal={setOpenSuccessModal}
                  drkToStake={drkToStake}
                  setClaimRewardsDialogOpen={setClaimRewardsDialogOpen}
                  setDrkToStake={setDrkToStake}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export { Stake };
