import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Header } from '../../../../components/header';

const MintedItemDetails = () => {
  const navigate = useNavigate();
  const {
    state: { item },
  } = useLocation();

  const {
    inscriptionId,
    inscription_id,
    id,
    meta: { name, image, description, type, rarity, trait },
  } = item;

  const finalInscriptionId = inscriptionId || inscription_id;

  return (
    <>
      <Header />
      <main className="main" style={{ minHeight: '70dvh' }}>
        <div className="container">
          <div className="row row--grid">
            {/* breadcrumb */}
            <div className="col-12">
              <ul className="breadcrumb">
                <li className="breadcrumb__item">
                  <Link onClick={() => navigate(-1)}>Inventory</Link>
                </li>
                <li className="breadcrumb__item breadcrumb__item--active">{id}</li>
              </ul>
            </div>
            {/* end breadcrumb */}
            {/* title */}
            <div className="col-12">
              <div className="main__title main__title--page">
                <h1>{name}</h1>
              </div>
            </div>
            {/* end title */}
          </div>
          <div className="row">
            {/* content */}
            <div className="col-12 col-xl-8">
              <div className="asset__item">
                <img src={`https://darkcity.infura-ipfs.io/ipfs/${image}`} alt="" />

                {/* share */}
                <div className="share share--asset">
                  {/* <a href="#" className="share__link share__link--tw">
                    <svg width={16} height={12} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.55075 3.19219L7.58223 3.71122L7.05762 3.64767C5.14804 3.40404 3.47978 2.57782 2.06334 1.1902L1.37085 0.501686L1.19248 1.01013C0.814766 2.14353 1.05609 3.34048 1.843 4.14552C2.26269 4.5904 2.16826 4.65396 1.4443 4.38914C1.19248 4.3044 0.972149 4.24085 0.951164 4.27263C0.877719 4.34677 1.12953 5.31069 1.32888 5.69202C1.60168 6.22165 2.15777 6.74068 2.76631 7.04787L3.28043 7.2915L2.67188 7.30209C2.08432 7.30209 2.06334 7.31268 2.12629 7.53512C2.33613 8.22364 3.16502 8.95452 4.08833 9.2723L4.73884 9.49474L4.17227 9.8337C3.33289 10.321 2.34663 10.5964 1.36036 10.6175C0.888211 10.6281 0.5 10.6705 0.5 10.7023C0.5 10.8082 1.78005 11.4014 2.52499 11.6344C4.75983 12.3229 7.41435 12.0264 9.40787 10.8506C10.8243 10.0138 12.2408 8.35075 12.9018 6.74068C13.2585 5.88269 13.6152 4.315 13.6152 3.56293C13.6152 3.07567 13.6467 3.01212 14.2343 2.42953C14.5805 2.09056 14.9058 1.71983 14.9687 1.6139C15.0737 1.41264 15.0632 1.41264 14.5281 1.59272C13.6362 1.91049 13.5103 1.86812 13.951 1.39146C14.2762 1.0525 14.6645 0.438131 14.6645 0.258058C14.6645 0.22628 14.5071 0.279243 14.3287 0.374576C14.1398 0.480501 13.7202 0.639389 13.4054 0.734722L12.8388 0.914795L12.3247 0.565241C12.0414 0.374576 11.6427 0.162725 11.4329 0.0991699C10.8978 -0.0491255 10.0794 -0.0279404 9.59673 0.14154C8.2852 0.618204 7.45632 1.84694 7.55075 3.19219Z" />
                    </svg>
                    <span>tweet</span>
                  </a> */}
                  {/* <button
                    className="share__link share__link--link"
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                    }}
                  >
                    <svg width={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M8,12a1,1,0,0,0,1,1h6a1,1,0,0,0,0-2H9A1,1,0,0,0,8,12Zm2,3H7A3,3,0,0,1,7,9h3a1,1,0,0,0,0-2H7A5,5,0,0,0,7,17h3a1,1,0,0,0,0-2Zm7-8H14a1,1,0,0,0,0,2h3a3,3,0,0,1,0,6H14a1,1,0,0,0,0,2h3A5,5,0,0,0,17,7Z" />
                    </svg>
                    <span>copy link</span>
                  </button> */}
                </div>
                {/* end share */}
              </div>
            </div>
            {/* end content */}
            {/* sidebar */}
            <div className="col-12 col-xl-4">
              <div className="asset__info">
                <div className="asset__desc">
                  <h2>Description</h2>
                  <p>{description}</p>
                </div>
                <ul className="asset__authors">
                  <li className="justify-content-between align-items-center flex-row" style={{ width: '100%' }}>
                    {/* <img src="img/dc.png" alt="" /> */}
                    <div>
                      <p className="m-0 column">{type}</p>
                      <a
                        href={`https://ordinals.com/inscription/${finalInscriptionId}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ fontSize: '14px' }}
                      >
                        On chain
                      </a>
                    </div>
                    <span className="card__time column m-0">{rarity}</span>
                  </li>
                </ul>
                <ul className="asset__authors">
                  <li className="justify-content-between align-items-center flex-row" style={{ width: '100%' }}>
                    <div>
                      <p className="m-0 column">{rarity}</p>
                    </div>
                  </li>
                </ul>

                {trait && (
                  <>
                    <h3 className="asset__authors" style={{ color: 'white', marginTop: '22px' }}>
                      Traits
                    </h3>
                    <ul className="list-unstyled text-white">
                      {Object.entries(trait).map(([trait, traitValue]) => (
                        <li key={trait} className="py-2">
                          <p style={{ color: '#bdbdbd', margin: 0 }}>
                            {trait} {traitValue}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                <div className="asset__btns">
                  <button className="asset__btn asset__btn--clr" type="button">
                    Yours
                  </button>
                  <div className="asset__btn open-modal" style={{ cursor: 'pointer' }}>
                    List
                  </div>
                </div>
                {/* end actions */}
              </div>
            </div>
            {/* end sidebar */}
          </div>
        </div>
      </main>
    </>
  );
};

export default MintedItemDetails;
