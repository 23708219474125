import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  const updateProfileMutation = useMutation({
    mutationKey: ['updateProfilePicture'],
    mutationFn: async (newUsersData) => {
      const body = {
        profilePicture: newUsersData.profileImage,
        backgroundImg: newUsersData.backgroundImg,
        name: newUsersData.name,
        profileUri: newUsersData.profileUri,
      };
      const response = await fetch(`${BASE_URL}/users`, {
        method: 'PUT',
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to update profile picture');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });

  return updateProfileMutation;
};

export { useUpdateProfile };
