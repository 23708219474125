import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../../config';
import { signMessagexVerse } from '../../../auth/xverse';
import { generateRandomMessage } from '../useStakeUnisat';

const useStakeXverse = () => {
  const queryClient = useQueryClient();

  const xVerseStakeMutation = useMutation({
    mutationKey: ['stake'],

    mutationFn: async ({ publicKey, address, amount }) => {
      const randomMessage = generateRandomMessage();

      const signature = await signMessagexVerse(address, randomMessage);

      const body = {
        publicKey: publicKey,
        message: randomMessage,
        signature: signature,
        address: address,
        amount: amount,
      };

      const response = await fetch(`${BASE_URL}/stake`, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to execute stake operation');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stakeData']);
    },
  });

  return xVerseStakeMutation;
};

export { useStakeXverse };
