import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { Zoom } from '@mui/material';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { QUESTIONS } from './questions';
import { useSendQuiz } from './useSendQuiz';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const QuizModal = ({ open, setOpen }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [error, setError] = useState('');
  const sendQuizMutation = useSendQuiz();

  const handleOptionChange = (event, answer) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(answer)) {
        return prevSelectedOptions.filter((option) => option !== answer);
      } else {
        return [...prevSelectedOptions, answer];
      }
    });
  };

  const handleConfirmAnswer = () => {
    const noAnswers = selectedOptions.length === 0;
    if (noAnswers) {
      setError('Please select answer');
      return;
    }

    //TODO: dynamic instead of static index
    const validAnswers = QUESTIONS[0].correctAnswers.slice().sort();
    const answers = selectedOptions.slice().sort();

    const answerNotCorrect = JSON.stringify(validAnswers) !== JSON.stringify(answers);

    if (answerNotCorrect) {
      setError('Wrong answer. Try again');
      return;
    }

    setError('');

    sendQuizMutation.mutate(
      {},
      {
        onSuccess: async () => {
          setError('Success');
        },
        onError: async () => {
          setError('Something went wrong');
        },
      }
    );
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  return (
    <Dialog
      data-testid="quiz-dialog"
      open={open}
      TransitionComponent={Transition}
      slotProps={{
        backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
      }}
      PaperProps={{ style: { backgroundColor: '#16151a', padding: '40px 80px 25px 80px', maxWidth: '600px' } }}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="quiz-modal"
    >
      <button
        className="modal__close"
        type="button"
        style={{ top: '37px', right: '37px' }}
        onClick={() => setOpen(false)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
        </svg>
      </button>
      <div className="d-flex align-items-center justify-content-center flex-column gap-3 px-4">
        <h4 className="sign__title mb-0 fs-5 text-center" style={{ color: 'white', fontWeight: 'normal' }}>
          {/* TODO: dynamic instead of static index */}
          {QUESTIONS[0].question}
        </h4>
        <Form className="mb-0 text-white mt-2 align-items-start">
          {/* TODO: dynamic instead of static index */}
          {QUESTIONS[0].answers?.map((answer, index) => (
            <Form.Check
              key={index}
              type="checkbox"
              label={`Option ${answer}`}
              id={`option${answer}`}
              name={`quizOptions${answer}`}
              value={answer}
              checked={selectedOptions.includes(answer)}
            >
              <Form.Check.Input
                className={selectedOptions.includes(answer) ? 'selected-option' : ''}
                onClick={(e) => handleOptionChange(e, answer)}
              />
              <Form.Check.Label>{answer}</Form.Check.Label>
            </Form.Check>
          ))}
        </Form>
        <button className="sign__btn mt-2 mb-0" type="button" onClick={handleConfirmAnswer}>
          OK
        </button>
        <p className="text-white text-center m-0" role="alert" aria-live="assertive" aria-atomic="true">
          {error}
        </p>
      </div>
    </Dialog>
  );
};

export { QuizModal };
