import { useQuery } from '@tanstack/react-query';
import { BASE_URL } from '../../../../config';
import { AUTHENTICATED_STATUS, NOT_AUTHENTICATED_STATUS, useStore } from '../../../store';

const useGetUsers = () => {
  const {
    setAuth,
    auth: { status },
  } = useStore();

  const url = `${BASE_URL}/users`;

  const users = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await fetch(url, {
        credentials: 'include',
      });

      const sessionExpired = status === AUTHENTICATED_STATUS && response.status === 401;

      if (sessionExpired) {
        setAuth({ status: NOT_AUTHENTICATED_STATUS, address: null, sessionExpired: true });
        return;
      } else if (!response.ok) {
        throw new Error('Failed to fetch users data');
      }

      const data = await response.json();
      return data;
    },
  });

  return users;
};

export { useGetUsers };
