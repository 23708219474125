import { useState } from 'react';
import { Header } from '../../components/header';
import cover1 from './assets/cover1.jpg';
import cover2 from './assets/cover2.jpg';
import { useGetOrders } from './useGetOrders';
import { Fallback } from './fallback';
import { Sidebar } from './sidebar';
import { useEffect } from 'react';

export const initialFilters = {
  type: {},
  rarity: {},
};

const Activity = () => {
  const [filters, setFilters] = useState(initialFilters);
  const [searchingPhrase, setSearchingPhrase] = useState('');
  const [filtersApplied, setFiltersApplied] = useState(false);

  const [visible, setVisible] = useState(15);
  const result = useGetOrders(filters, searchingPhrase);
  const { data, isLoading, error, refetch } = result;

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 5);
  };

  useEffect(() => {
    if (filtersApplied) refetch();
  }, [filtersApplied, refetch]);

  return (
    <>
      <Header />
      <main className="main">
        <div className="container">
          <div className="row row--grid">
            {/* title */}
            <div className="col-12">
              <div className="main__title main__title--page">
                <h1>Activity</h1>
              </div>
            </div>
            {/* end title */}
          </div>
          <div className="row">
            <Sidebar
              filters={filters}
              setFilters={setFilters}
              result={result}
              searchingPhrase={searchingPhrase}
              setSearchingPhrase={setSearchingPhrase}
              setFiltersApplied={setFiltersApplied}
            />

            {/* content */}
            <div className="col-12 col-xl-9 order-xl-1">
              <div className="row row--grid">
                {isLoading && data === undefined && <Fallback text="Loading data..." />}
                {error && <Fallback text="Error while fetching data..." />}
                {data?.length === 0 && <Fallback text="No items match the given criteria..." />}
                {data &&
                  data.slice(0, visible).map((order) => (
                    <div className="col-12 col-lg-6" key={order.id}>
                      <div className="activity">
                        {/* hard navigation needed, because otherwise carousel is not loading */}
                        <a href="activity/1" className="activity__cover">
                          <img src={`https://darkcity.infura-ipfs.io/ipfs/${order.meta.image}`} alt="" />
                        </a>

                        <div className="activity__content">
                          <h3 className="activity__title">
                            <a href="activity/1">{order.meta.name}</a>
                          </h3>
                          <p className="activity__text">
                            listed by <a href="author.html">Dark city</a> <br />
                            for <b>300 DRK</b>
                          </p>
                          <span className="activity__time">4 minutes ago</span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {/* collapse */}
              <div className="row row--grid collapse" id="collapsemore">
                <div className="col-12 col-lg-6">
                  <div className="activity">
                    <a href="activity/3" className="activity__cover">
                      <img src={cover1} alt="" />
                    </a>
                    <div className="activity__content">
                      <h3 className="activity__title">
                        <a href="activity/3">Walking on Air</a>
                      </h3>
                      <p className="activity__text">
                        listed by <a href="author.html">@Nickname</a> <br />
                        for <b>0.049 ETH</b>
                      </p>
                      <span className="activity__time">4 minutes ago</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="activity">
                    <a href="activity/4" className="activity__cover">
                      <img src={cover2} alt="" />
                    </a>
                    <div className="activity__content">
                      <h3 className="activity__title">
                        <a href="activity/4">To Infinity And Beyond</a>
                      </h3>
                      <p className="activity__text">
                        9 editions listed by <a href="author.html">@neo</a> <br />
                        for <b>0.085 ETH</b> each
                      </p>
                      <span className="activity__time">21 minutes ago</span>
                    </div>
                  </div>
                </div>
              </div>
              {data && visible < data.length && (
                <div className="row row--grid">
                  <div className="col-12">
                    <button className="main__load" onClick={showMoreItems}>
                      Load more
                    </button>
                  </div>
                </div>
              )}
              {/* end collapse */}
            </div>
            {/* end content */}
          </div>
        </div>
      </main>
    </>
  );
};

export { Activity };
