import { Fallback } from './fallback';
import icon from '../../../assets/images/icon/dark_city_icon_small.png';
import { useState } from 'react';
import { BuyItemModal } from '../../../components/buy-modal';
import { NotLoggedModal } from '../../../components/not-logged-modal';
import { useStore } from '../../../store';
import { AddToCartToggle, isItemInCart } from '../../../components/add-to-cart-toggle';

const Listings = ({ profileDataResults }) => {
  const {
    auth: { status },
    cart,
  } = useStore();
  const { isLoading, isError } = profileDataResults;
  const [open, setOpen] = useState(false);
  const [itemToBuy, setItemToBuy] = useState(null);

  const data = profileDataResults.data.listedItems;

  const inventoryEmpty = data?.length === 0;

  const handleClickOpen = (e, listing) => {
    const item = { id: listing.id, price: listing.price, name: listing.meta.name, image: listing.meta.image };
    setItemToBuy(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const displayFallback = () => {
    if (isLoading) return <Fallback text="Loading data..." />;
    if (isError) return <Fallback text="Error while loading listings..." />;
    if (inventoryEmpty) return <Fallback text="No listings available..." />;
  };

  return (
    <>
      {status === 'authenticated' ? (
        <BuyItemModal open={open} handleClose={handleClose} itemToBuy={itemToBuy} />
      ) : (
        <NotLoggedModal open={open} handleClose={handleClose} />
      )}
      {data?.length > 0 ? (
        <>
          {/* <Sidebar /> */}
          <div className="col-12 col-xl-8 order-xl-1">
            <div className="row row--grid">
              {data?.map((listing) => (
                <div className="col-12 col-lg-6 col-xl-12" key={listing.orderedAt}>
                  <div
                    className="activity"
                    style={{ outline: isItemInCart(cart, listing) ? '2px solid #6164ff' : '1px solid #222227' }}
                  >
                    <div className="activity__cover">
                      <img src={`https://darkcity.infura-ipfs.io/ipfs/${listing.meta.image}`} alt="" />
                    </div>
                    <div className="activity__content">
                      <div className="d-flex">
                        <h3 className="activity__title">
                          <div>{listing.meta.name}</div>
                        </h3>
                        <AddToCartToggle item={listing} setOpen={setOpen} />
                      </div>
                      <p className="activity__text">
                        {listing.meta.type}
                        <br />
                        {listing.meta.rarity}
                      </p>
                      <span className="activity__time fs-6 text-white d-flex align-items-center justify-content-between">
                        <div>
                          <img width={22} height={22} src={icon} alt="" style={{ borderRadius: '50%' }} />
                          <span className="ml-2">{listing.price} DRK</span>
                        </div>
                        <div className="filter__group w-auto">
                          <button
                            className="filter__btn py-2 px-3 h-auto w-auto text-capitalize"
                            type="button"
                            onClick={(e) => handleClickOpen(e, listing)}
                          >
                            Buy now
                          </button>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        displayFallback()
      )}
    </>
  );
};

export { Listings };
