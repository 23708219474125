import { Link } from 'react-router-dom';
import { formatBitcoinAddress } from '../../../components/header';
import { useStore } from '../../../store';
import logo from './assets/dark_city_logo.jpg';
import { Fallback } from './fallback';
import { useGetOrders } from './useGetOrders';

export function convertTimestamp(timestamp) {
  const date = new Date(timestamp * 1000);

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'UTC',
  };

  const formattedDate = date.toLocaleString('pl', options);

  return formattedDate;
}

// TODO: paginacja
const MyOrders = () => {
  const result = useGetOrders();
  const { data, isLoading, isError } = result;
  const {
    auth: { status },
  } = useStore();

  const inventoryEmpty = data?.length === 0;
  const notConnected = status === 'not-authenticated';

  const displayFallback = () => {
    if (notConnected) return <Fallback text="Connect to your wallet to view orders..." />;
    if (isLoading) return <Fallback text="Loading data..." />;
    if (isError) return <Fallback text="Error while loading orders..." />;
    if (inventoryEmpty) return <Fallback text="No orders available..." />;
  };

  const RegularOrder = ({ order }) => {
    return (
      <>
        <div className="activity__cover">
          <img src={`https://darkcity.infura-ipfs.io/ipfs/${order.itemImg}`} alt="" />
        </div>
        <div className="activity__content">
          <h3 className="activity__title">
            <div>{order.itemName}</div>
          </h3>
          <p className="activity__text">
            listed by Moon Runners <br />
            for <b>{order.price} DRK</b>
          </p>
          <span className="activity__time">{convertTimestamp(order.at)} UTC</span>
        </div>
      </>
    );
  };

  const ItemOrder = ({ order }) => {
    return (
      <>
        <div className="activity__cover">
          <img src={`https://darkcity.infura-ipfs.io/ipfs/${order.itemImg}`} alt="" />
        </div>
        <div className="activity__content">
          <h3 className="activity__title">
            <div>{order.name}</div>
          </h3>
          <p className="activity__text">
            from <b>{formatBitcoinAddress(order.from)}</b>
            <br />
            to <b>{formatBitcoinAddress(order.to)}</b>
          </p>
          <span className="activity__time">{convertTimestamp(order.at)} UTC</span>
        </div>
      </>
    );
  };

  const DrkTokenOrder = ({ order }) => {
    return (
      <>
        <div className="activity__cover">
          <img src={logo} alt="" />
        </div>
        <div className="activity__content">
          <h3 className="activity__title">
            <div>{order.amount} DRK</div>
          </h3>
          <div className="activity__text">
            <p className="m-0">
              from <b>{formatBitcoinAddress(order.from)}</b>
            </p>
            <p className="m-0">
              to <b>{formatBitcoinAddress(order.to)}</b>
            </p>
          </div>
          <span className="activity__time">{convertTimestamp(order.at)} UTC</span>
        </div>
      </>
    );
  };

  const applyStylesForOrder = (order) => {
    const orderType = order?.type;

    if (orderType === 'order') return {};
    if (orderType === 'incomingTransfers')
      return {
        backgroundColor: 'rgba(0, 255, 0, 0.15)',
      };
    if (orderType === 'outgoingTransfer')
      return {
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
      };
  };

  return (
    <>
      {data?.length > 0 && !notConnected ? (
        <>
          {/* <Sidebar /> */}
          <div className="col-12 col-xl-8 order-xl-1">
            <div className="row row--grid">
              {data?.map((order, index) => (
                <div className="col-12 col-lg-6 col-xl-12" key={index}>
                  <div className="activity" style={applyStylesForOrder(order)}>
                    {order?.type === 'order' && <RegularOrder order={order} />}
                    {order?.itemId && order?.type !== 'order' && <ItemOrder order={order} />}
                    {order?.amount && <DrkTokenOrder order={order} />}

                    <Link
                      className="text-center align-self-center header__nav-link fs-6"
                      to={`/orders/${order.id}`}
                      style={{ visibility: order.type === 'order' ? 'hidden' : 'visible' }}
                    >
                      See details
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        displayFallback()
      )}
    </>
  );
};

export { MyOrders };
