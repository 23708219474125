import { Zoom } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const NotLoggedModal = ({ handleClose, open }) => {
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
        }}
        PaperProps={{ style: { backgroundColor: '#16151a', padding: '40px 40px 25px 40px', maxWidth: '520px' } }}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <button className="modal__close" type="button" style={{ top: '37px', right: '37px' }} onClick={handleClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
          </svg>
        </button>
        <h4 className="sign__title">Not connected</h4>
        <p className="modal__text mt-2">You need to connect to your wallet to buy items</p>
        <button className="sign__btn mt-2" type="button" onClick={handleClose}>
          Close
        </button>
      </Dialog>
    </>
  );
};

export { NotLoggedModal };
