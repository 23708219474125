import { Navigate, useParams } from 'react-router-dom';

const RedirectToOffchainItems = () => {
  const { walletId } = useParams();

  return (
    <>
      <Navigate to={`/users/${walletId}/off-chain/grouped/1`} />
    </>
  );
};

export { RedirectToOffchainItems };
