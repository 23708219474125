const NotAuthenticated = ({ setOpen }) => {
  return (
    <>
      <button
        className="modal__close"
        type="button"
        style={{ top: '37px', right: '37px' }}
        onClick={() => setOpen(false)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
        </svg>
      </button>

      <label className="filter__label fs-5 text-center">You need to connect to your wallet first!</label>

      <button
        className="sign__btn mt-4"
        style={{ maxWidth: '120px', height: '40px' }}
        type="button"
        onClick={() => setOpen(false)}
      >
        Close
      </button>
    </>
  );
};

export { NotAuthenticated };
