import React, { useState } from 'react';
import { formatBitcoinAddress } from '../header';
import { useQueries } from '@tanstack/react-query';
import { SuccessModalUnisat } from '../success-modal-unisat';

const fetchData = async (id) => {
  const url = `https://ordinals.com/content/${id}`;
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

const InscriptionList = ({ setOpen, data }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const combinedQueries = useQueries({
    queries: data.map((item) => ({
      queryKey: ['ordinalsData', item.inscriptionId],
      queryFn: () => fetchData(item.inscriptionId),
    })),
    combine: (results) => {
      return {
        data: results.map((result, index) => ({
          amt: result.data?.amt,

          // Jeśli API trac się zjebało, to to też nie zadziała
          // Bo indeksy mogą być nie-aktualne

          inscriptionId: data[index].inscriptionId,
          walletAddress: data[index].walletAddress,
          feeRate: data[index].feeRate,
        })),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  const handleRadioChange = (item) => {
    setSelectedItem(item);
  };

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [transactionId, setTransactionId] = useState('');

  const transferSelectedInscription = async () => {
    const { walletAddress, inscriptionId, feeRate } = selectedItem;

    try {
      let { txid } = await window.unisat.sendInscription(walletAddress, inscriptionId, { feeRate: feeRate });
      if (txid) {
        setTransactionId(txid);
        setOpenSuccessModal(true);
      }
    } catch (e) {
      console.log(e);
    }

    setOpen(false);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setOpen(false);
  };

  return (
    <>
      <SuccessModalUnisat open={openSuccessModal} setOpen={setOpenSuccessModal} transactionId={transactionId} />
      <button className="modal__close" type="button" style={{ top: '17px', right: '17px' }} onClick={handleClose}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
        </svg>
      </button>

      <label className="filter__label text-center fs-4">Select amount to convert</label>
      {combinedQueries?.data?.length > 0 ? (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center', marginTop: '12px' }}>
            {combinedQueries.data.map((item) => (
              <React.Fragment key={item?.inscriptionId}>
                <label htmlFor={`radio-${item?.inscriptionId}`} className="d-flex mt-2">
                  <div
                    className="text-white p-3 text-center d-flex flex-column"
                    style={{
                      backgroundColor:
                        selectedItem?.inscriptionId === item?.inscriptionId ? 'rgba(50, 50, 55, 1)' : 'transparent',
                      outline:
                        selectedItem?.inscriptionId === item?.inscriptionId ? '2px solid white' : '1px solid white',
                    }}
                  >
                    <input
                      type="radio"
                      id={`radio-${item?.inscriptionId}`}
                      name="radioGroup"
                      onChange={() => handleRadioChange(item)}
                      checked={selectedItem === item}
                    />
                    <p className="m-0 fs-6">{formatBitcoinAddress(item?.inscriptionId)}</p>
                    <p className="text-white m-0" style={{ fontSize: '14px' }}>
                      {item?.amt} DRK
                    </p>
                  </div>
                </label>
              </React.Fragment>
            ))}
          </div>
          <button
            className="sign__btn"
            style={{ marginTop: '30px' }}
            type="button"
            onClick={transferSelectedInscription}
          >
            Convert
          </button>
          <div className="text-white mt-2 text-center">
            <strong>Important:</strong> After converting $DRK to DRK credits,{' '}
            <strong>you can&rsquo;t convert it back</strong>. You can buy as many items your balance of DRK credits
            allows you.
          </div>
        </>
      ) : (
        <div className="text-white text-center mt-2">
          You don&rsquo;t have any $DRK transfer inscriptions to convert to DRK credits.
        </div>
      )}
    </>
  );
};

export { InscriptionList };
