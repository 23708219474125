import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

export const CART_OUTDATED_STATUS = 'cartOutdated';

const useBatchBuy = () => {
  const queryClient = useQueryClient();

  const batchBuyMutation = useMutation({
    mutationKey: ['batchBuy'],
    mutationFn: async (cart) => {
      const itemsWithPrices = cart.map((item) => ({ id: item.id, price: item.price }));

      const response = await fetch(`${BASE_URL}/orders`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(itemsWithPrices),
      });

      // Price of some items might be outdated, or even they were sold. Here we handle this case
      if (response.status === 400) {
        const data = await response.json();

        return {
          outdatedItems: { ...data },
          type: CART_OUTDATED_STATUS,
        };
      }

      if (!response.ok) {
        throw new Error('Failed to execute batch buy operation');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['clusteredItems']);
      queryClient.invalidateQueries(['grouped']);
      queryClient.invalidateQueries(['ungrouped']);
      queryClient.invalidateQueries(['item']);
    },
  });

  return batchBuyMutation;
};
export { useBatchBuy };
