import { useQuery } from '@tanstack/react-query';
import { BASE_URL } from '../../../../config';

const useInventoryItemsQuery = (walletId) => {
  const darkCityInventoryResults = useQuery({
    queryKey: ['inventory', walletId],
    queryFn: async () => {
      const response = await fetch(`${BASE_URL}/users/profiles/${walletId}`);

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      return response.json();
    },
    enabled: !!walletId,
  });

  return darkCityInventoryResults;
};

export { useInventoryItemsQuery };
