import { useQuery } from '@tanstack/react-query';
import { buildUrl, fetchData } from '../utils';
import { BASE_URL } from '../../../../config';

const useGroupedItemsQuery = (filters) => {
  const url = buildUrl(filters, `${BASE_URL}/items/groups`);

  const itemsGroupedResult = useQuery({
    queryKey: ['grouped'],
    queryFn: () => fetchData(url),
    staleTime: 0,
  });

  return itemsGroupedResult;
};
export { useGroupedItemsQuery };
