import { Zoom } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { validate } from 'bitcoin-address-validation';
import * as React from 'react';
import { useState } from 'react';
import { useGiftItem } from '../../pages/your-inventory/unofficial-items/useGiftItem';
import { useStore } from '../../store';
import { SuccessModal } from '../success-modal';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const GiftItemModal = ({ setOpen, open, itemId }) => {
  const [receiverWalletAddress, setReceiverWalletAddress] = useState('');
  const [error, setError] = React.useState('');
  const {
    auth: { status },
  } = useStore();

  const handleReceiverAddressChange = (e) => {
    setReceiverWalletAddress(e.target.value);
  };

  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const giftDrkMutation = useGiftItem(itemId);

  const giftDrk = () => {
    const addressNotValid = !validate(receiverWalletAddress);

    if (!receiverWalletAddress) {
      setError('Please select recipient wallet address');
      return;
    }

    if (addressNotValid) {
      setError(`Please select correct recipient wallet address`);
      return;
    }

    setError('');

    giftDrkMutation.mutate(receiverWalletAddress, {
      onSuccess: async () => {
        setOpenSuccessModal(true);
      },
    });

    setOpen(false);
  };

  return (
    <>
      <SuccessModal open={openSuccessModal} setOpen={setOpenSuccessModal}>
        <h4 className="sign__title" style={{ color: 'white', fontWeight: 'normal' }}>
          Item gifted successfully
        </h4>
      </SuccessModal>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
        }}
        PaperProps={{
          style: { backgroundColor: '#16151a', padding: '80px 80px 25px 80px', width: '550px' },
        }}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        {status === 'authenticated' && itemId && (
          <>
            <button
              className="modal__close"
              type="button"
              style={{ top: '37px', right: '37px' }}
              onClick={() => setOpen(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
              </svg>
            </button>
            <div className="filter__group align-items-center">
              <label className="filter__label">Choose the recipient&rsquo;s wallet address.</label>
              <input
                data-testid="gift-item-input"
                type="text"
                className="filter__input text-center"
                value={receiverWalletAddress}
                onChange={handleReceiverAddressChange}
              />
            </div>
            {error && <div className="text-white text-center">{error}</div>}
            <button className="sign__btn mt-4" style={{ maxWidth: '120px' }} type="button" onClick={giftDrk}>
              Gift
            </button>
          </>
        )}
      </Dialog>
    </>
  );
};

export { GiftItemModal };
