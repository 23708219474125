import { useMutation } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const useMintDrk = () => {
  const mintMutation = useMutation({
    mutationKey: ['mint'],
    mutationFn: async (amount) => {
      const body = { amount: amount };
      const response = await fetch(`${BASE_URL}/users/drk/mint`, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to mint DRK');
      }

      return response.json();
    },
  });

  return mintMutation;
};

export { useMintDrk };
