import { useMutation } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const useTransferDrk = () => {
  const transferDrkMutation = useMutation({
    mutationKey: ['transferDrk'],
    mutationFn: async () => {
      const response = await fetch(`${BASE_URL}/users/drk/transfer`, {
        method: 'POST',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch transfer data');
      }

      return response.json();
    },
  });

  return transferDrkMutation;
};

export { useTransferDrk };
