import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { BASE_URL } from '../../../config';
import { AddToCartToggle, isItemInCart } from '../../components/add-to-cart-toggle';
import { BuyItemModal } from '../../components/buy-modal';
import { Header, formatBitcoinAddress } from '../../components/header';
import { NotLoggedModal } from '../../components/not-logged-modal';
import { useStore } from '../../store';
import { BS_Carousel } from './Carousel';
import { Fallback } from './fallback';
import dark_city_icon from './img/dark-city-icon.jpg';
import { applySearching, fetchData } from './utils';

const UngroupedItems = () => {
  const [visible, setVisible] = useState(15);
  const [searchingPhrase, setSearchingPhrase] = useState('');
  const [open, setOpen] = useState(false);
  const [itemToBuy, setItemToBuy] = useState(null);
  const {
    auth: { status },
    cart,
  } = useStore();

  const handleClickOpen = (e, item) => {
    setItemToBuy(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 5);
  };

  const { name } = useParams();

  const url = `${BASE_URL}/items?name=${name}`;

  const grupedItemsResult = useQuery({
    queryKey: ['clustered-items', name],
    queryFn: () => fetchData(url),
    select: (data) => {
      return data.map((item) => ({
        id: item.id,
        name: item.meta.name,
        type: item.meta.type,
        image: item.meta.image,
        rarity: item.meta.rarity,
        description: item.meta.description,
        inscription_id: item.inscription_id,
        price: item.price,
        listedBy: item.ownerWalletAddress,
      }));
    },
  });
  const { data, isLoading, error } = grupedItemsResult;

  // TODO: szukajka jak będzie endpoint analogicznie jak w all-items
  const searchedItems = applySearching(data, searchingPhrase);

  return (
    <>
      <div className="home-8">
        {status === 'authenticated' ? (
          <BuyItemModal open={open} handleClose={handleClose} itemToBuy={itemToBuy} />
        ) : (
          <NotLoggedModal open={open} handleClose={handleClose} />
        )}

        <Header searchingPhrase={searchingPhrase} setSearchingPhrase={setSearchingPhrase} />
        <main className="main" style={{ minHeight: '70dvh' }}>
          <div className="container">
            <div style={{ marginTop: '120px' }}>
              <BS_Carousel />
            </div>
            <div className="row row--grid">
              {/* title */}
              <div className="col-12">
                <div className="main__title main__title--page">
                  <h1>{name}&apos;s</h1>
                </div>
              </div>
              {/* end title */}
            </div>
            <div className="row">
              {/* content */}
              <div className="col-12 col-xl-12 order-xl-1">
                <div className="row row--grid">
                  {isLoading && data === undefined && <Fallback text="Loading data..." />}
                  {error && <Fallback text="Error while fetching data..." />}
                  {data?.length === 0 && <Fallback text="No items match the given criteria..." />}
                  {searchedItems &&
                    searchedItems.slice(0, visible).map((item, index) => (
                      <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                        <div
                          className="card pb-3"
                          style={{ outline: isItemInCart(cart, item) ? '2px solid #6164ff' : '1px solid #222227' }}
                        >
                          <Link
                            to={item?.id ? `/explore/${item.name}/${item.id}` : `${item.name}`}
                            state={{ item: item }}
                            className="card__cover"
                          >
                            <img src={`https://darkcity.infura-ipfs.io/ipfs/${item.image}`} alt="" />
                          </Link>
                          <div className="d-flex justify-content-between align-items-center w-100 mt-2">
                            <h3 className="card__title m-0">
                              <Link
                                to={item?.id ? `/explore/${item.name}/${item.id}` : `${item.name}`}
                                state={{ item: item }}
                              >
                                {item.name}
                              </Link>
                            </h3>
                            <div className="card__price">
                              <img src={dark_city_icon} alt="" className="rounded-circle" height="24px" width="24px" />
                              <span className="mt-0 pl-2">{item?.price}</span>
                            </div>
                          </div>
                          <p className="m-0 mt-3" style={{ color: '#bdbdbd' }}>
                            {item.type}
                          </p>
                          <p className="m-0 mt-0" style={{ color: '#bdbdbd', fontSize: '14px' }}>
                            {item.rarity}
                          </p>
                          <p className="m-0 mt-3 text-white" style={{ fontSize: '13px' }}>
                            Listed by{' '}
                            {item?.listedBy ? (
                              <Link
                                to={`/users/${item?.listedBy}`}
                                className="card__title d-inline"
                                style={{ fontSize: '13px' }}
                              >
                                {formatBitcoinAddress(item?.listedBy)}
                              </Link>
                            ) : (
                              <Link to="/explore" className="card__title d-inline" style={{ fontSize: '13px' }}>
                                Moon Runners
                              </Link>
                            )}
                          </p>
                          <div className="card__info align-items-center pt-3">
                            <AddToCartToggle item={item} setOpen={setOpen} />
                            <div className="filter__group w-auto">
                              <button
                                className="filter__btn py-2 px-3 h-auto w-auto text-capitalize"
                                type="button"
                                onClick={(e) => handleClickOpen(e, item)}
                              >
                                Buy now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {searchedItems && visible < searchedItems.length && (
                  <div className="row row--grid">
                    <div className="col-12">
                      <button className="main__load" onClick={showMoreItems}>
                        Load more
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
export { UngroupedItems };
