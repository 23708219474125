import Spinner from 'react-bootstrap/Spinner';

export const LoadingUser = () => {
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center gap-4">
        <div>
          <Spinner animation="border" role="status" style={{ color: '#6164ff', width: '170px', height: '170px' }}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        <div role="alert" aria-live="polite" className="text-white text-center fs-4">
          Loading user data...
        </div>
      </div>
    </>
  );
};
