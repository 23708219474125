import { Navigate, useParams } from 'react-router-dom';

const RedirectToCustomOffchainItems = () => {
  const { postfix } = useParams();

  return (
    <>
      <Navigate to={`/shop/${postfix}/off-chain/grouped/1`} />
    </>
  );
};

export { RedirectToCustomOffchainItems };
