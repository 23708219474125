import { Zoom } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { useState } from 'react';
import { createInscription } from 'sats-connect';
import { useMintDrk } from '../../pages/your-inventory/unofficial-items/useMintDrk';
import { useGetInscriptionDataForDrkMint } from '../../pages/your-inventory/unofficial-items/useMintDrkXverse';
import { useStore } from '../../store';
import { SuccessModalUnisat } from '../success-modal-unisat';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const ConvertModal = ({ setOpen, open, usersData }) => {
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState('');
  const {
    auth: { status, walletType },
  } = useStore();

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [transactionId, setTransactionId] = useState('');

  const mintDrkMutation = useMintDrk();
  const xverseInscriptionMutation = useGetInscriptionDataForDrkMint();

  const mint = () => {
    if (!value || value === '0') {
      setError('Please select amount');
      return;
    }
    if (usersData?.balanceDRK / 10 ** 18 < Number(value)) {
      setError('You cannot mint more DRK than you own!');
      return;
    }

    setError('');
    if (walletType === 'unisat') {
      handleMintingDrkUnisat();
    } else if (walletType === 'xverse') {
      handleMintingDrkXverse();
    }
    setOpen(false);
  };

  const handleMintingDrkUnisat = () => {
    //first do api call to get needed data
    mintDrkMutation.mutate(String(value), {
      onSuccess: async (data) => {
        try {
          let txid = await window.unisat.sendBitcoin(data?.walletAddress, data?.paymentAmount, {
            feeRate: data?.feeRate,
          });
          if (txid) {
            setTransactionId(txid);
            setOpenSuccessModal(true);
          }
        } catch (e) {
          console.log(e);
        }
      },
    });
  };

  function handleMintingDrkXverse() {
    //first do api call to get needed data
    xverseInscriptionMutation.mutate(String(value), {
      onSuccess: async (data) => {
        await createInscription({
          payload: {
            network: {
              type: 'Mainnet',
            },
            content: data?.content,
            contentType: data?.contentType,
            payloadType: data?.payloadType,
            suggestedMinerFeeRate: data?.feeRate,
          },
          onFinish: (response) => {
            setTransactionId(response.txId);
            setOpenSuccessModal(true);
          },
          onCancel: () => alert('Canceled'),
        });
      },
    });
  }

  return (
    <>
      <SuccessModalUnisat open={openSuccessModal} setOpen={setOpenSuccessModal} transactionId={transactionId} />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
        }}
        PaperProps={{
          style: { backgroundColor: '#16151a', padding: '80px 80px 25px 80px', width: '500px' },
        }}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        {status === 'authenticated' && Number(usersData?.balanceDRK) === 0 && (
          <>
            <button
              className="modal__close"
              type="button"
              style={{ top: '37px', right: '37px' }}
              onClick={() => setOpen(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
              </svg>
            </button>
            <div className="text-white text-center">You don&rsquo;t have any $DRK to convert to DRK credits.</div>
            <button
              className="sign__btn mt-4"
              style={{ maxWidth: '120px' }}
              type="button"
              onClick={() => setOpen(false)}
            >
              OK
            </button>
          </>
        )}
        {status === 'authenticated' && usersData?.balanceDRK > 0 && (
          <>
            <button
              className="modal__close"
              type="button"
              style={{ top: '37px', right: '37px' }}
              onClick={() => setOpen(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
              </svg>
            </button>
            <div className="filter__group align-items-center">
              <label className="filter__label" htmlFor="drkConvertLabel">
                Select amount of $DRK to convert to DRK credits:
              </label>
              <input
                id="drkConvertLabel"
                type="number"
                className="filter__input"
                value={value}
                onChange={handleInputChange}
              />
            </div>
            {error && (
              <div className="text-white text-center" role="alert" aria-live="assertive" aria-atomic="true">
                {error}
              </div>
            )}
            <button
              className="sign__btn mt-4"
              style={{ maxWidth: '120px' }}
              type="button"
              onClick={mint}
              data-testid="mint-transfer-button"
            >
              Mint
            </button>
          </>
        )}
        {status === 'not-authenticated' && (
          <>
            <button
              className="modal__close"
              type="button"
              style={{ top: '37px', right: '37px' }}
              onClick={() => setOpen(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
              </svg>
            </button>

            <label className="filter__label fs-5 text-center">You need to connect to your wallet first!</label>

            <button
              className="sign__btn mt-4"
              style={{ maxWidth: '120px', height: '40px' }}
              type="button"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
          </>
        )}
      </Dialog>
    </>
  );
};

export { ConvertModal };
