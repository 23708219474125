import { useEffect } from 'react';
import { NOT_AUTHENTICATED_STATUS, useStore } from '../../store';

const useSessionExpired = (setSessionExpiredModalOpen) => {
  const store = useStore();

  useEffect(() => {
    if (store?.auth?.sessionExpired) {
      store.setAuth({ status: NOT_AUTHENTICATED_STATUS, address: null, sessionExpired: false });
      setSessionExpiredModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.auth?.sessionExpired]);
};

export { useSessionExpired };
