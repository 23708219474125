import { useQuery } from '@tanstack/react-query';
import { buildUrl, fetchData } from '../utils';
import { BASE_URL } from '../../../../config';

const useUngroupedItemsQuery = (filters, searchingPhrase) => {
  const allItemsUrl = buildUrl(filters, `${BASE_URL}/items`);
  const searchUrl = allItemsUrl + `&search=${searchingPhrase}`;

  const resultWithSearching = useQuery({
    queryKey: ['ungrouped'],
    queryFn: () => fetchData(searchUrl),
    select: (data) => {
      return data.map((item) => ({
        id: item.id,
        name: item.meta.name,
        type: item.meta.type,
        image: item.meta.image,
        rarity: item.meta.rarity,
        description: item.meta.description,
        inscription_id: item.inscription_id,
        price: item.price,
        listedBy: item?.ownerWalletAddress,
      }));
    },

    enabled: false,
  });

  return resultWithSearching;
};
export { useUngroupedItemsQuery };
