import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const useGiftItem = (itemId) => {
  const queryClient = useQueryClient();

  const giftItemMutation = useMutation({
    mutationKey: ['giftItem'],
    mutationFn: async (receiverWalletAddress) => {
      const body = { walletAddress: receiverWalletAddress };
      const response = await fetch(`${BASE_URL}/transfers/items/${itemId}`, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to gift item');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['orders']);
      queryClient.invalidateQueries(['offchainItems']);
    },
  });

  return giftItemMutation;
};

export { useGiftItem };
