import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CART_OUTDATED_STATUS, useBatchBuy } from './batch-buy-mutation';
import dark_city_icon from '../icons/dark-city-icon.jpg';
import { useStore } from '../../../store';

const Cart = ({ setCartVisible, setOpenSuccessModal, setErrorModalOpen, usersData }) => {
  const [error, setError] = useState('');
  const { cart, removeFromCart, clearCart } = useStore();

  function sumPrices(cart) {
    return cart.reduce((total, item) => total + parseFloat(item.price), 0);
  }

  const calculateDrkCreditsBalance = (balance) => {
    return balance / 10 ** 18;
  };

  const batchBuyMutation = useBatchBuy();
  const navigate = useNavigate();

  const buyCartItems = () => {
    const balanceDrk = calculateDrkCreditsBalance(usersData?.offChainDRK);
    if (balanceDrk < sumPrices(cart)) {
      setError('Not sufficient DRK credits balance');
      return;
    }

    batchBuyMutation.mutate(cart, {
      onSuccess: async (data) => {
        if (data.type === CART_OUTDATED_STATUS) {
          const oudatedItems = data.outdatedItems;
          const formattedOutdatedItems = Object.entries(oudatedItems).map(([id, price]) => ({
            id,
            price,
          }));

          formattedOutdatedItems.forEach((outdatedItem) => {
            removeFromCart(outdatedItem);
          });
          setErrorModalOpen(true);
        } else {
          setError('');
          setOpenSuccessModal(true);
          setTimeout(() => {
            navigate('/your-inventory/not-minted/grouped/1');
            setOpenSuccessModal(false);
            clearCart();
          }, 2000);
        }
      },
    });
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: '40px',
        width: '80%',
        maxWidth: '440px',
        maxHeight: '100vh',
        backgroundColor: '#16151a',
        zIndex: 3,
        color: 'white',
        border: '1px solid gray',
        borderTop: 'none',
        borderRadius: '0px 0px 14px 14px',
        textAlign: 'center',
        padding: '18px 24px 18px 24px',
        overflow: 'auto',
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <p className="m-0">Cart</p>
        <div className="d-flex gap-3">
          <button type="button" className="clear-cart-button" onClick={clearCart} aria-label="clear-cart">
            <span>Clear</span>
          </button>
          <button
            style={{ width: '20px', height: '20px', marginTop: '1px' }}
            type="button"
            onClick={() => setCartVisible(false)}
            className="close-cart-button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
            </svg>
          </button>
        </div>
      </div>

      <hr style={{ opacity: 0.4 }} />
      {cart.length === 0 ? (
        <p className="m-0">No items added to cart</p>
      ) : (
        <ul className="mt-4">
          {cart.map((item) => (
            <li key={item.id} className="mb-3 d-flex align-items-center justify-content-between">
              <div className="d-flex gap-4 align-items-center">
                <div className="position-relative">
                  <img
                    src={`https://darkcity.infura-ipfs.io/ipfs/${item.image || item?.meta?.image}`}
                    alt=""
                    width={60}
                    height={60}
                    style={{ borderRadius: '20%' }}
                  />
                  <button
                    className="position-absolute remove-item-cart-button"
                    onClick={() => removeFromCart(item)}
                    data-testid="cart-item-remove"
                  >
                    <span>x</span>
                  </button>
                </div>
                <span>{item.name || item.meta?.name}</span>
              </div>
              <div className="card__price gap-0">
                <img src={dark_city_icon} alt="" className="rounded-circle" height="26px" width="26px" />
                <span className="mt-0 pl-2">{item?.price}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
      {cart.length > 0 && (
        <>
          <hr style={{ opacity: 0.4 }} />
          <p className="mb-2" style={{ textAlign: 'start' }}>
            <b>{cart.length}</b> items in cart
          </p>
          <div className="d-flex justify-content-between">
            <label style={{ marginTop: '2px' }} className="mb-0" id="totalPrice">
              Total price
            </label>
            <div className="card__price gap-0">
              <img src={dark_city_icon} alt="" className="rounded-circle" height="26px" width="26px" />
              <span className="mt-0 pl-2" aria-labelledby="totalPrice">
                {sumPrices(cart)}
              </span>
            </div>
          </div>
          <div className="mt-4">
            <button
              className="filter__btn py-2 px-3 h-auto w-100 text-capitalize"
              type="button"
              onClick={buyCartItems}
              data-testid="cart-buy-button"
            >
              Buy
            </button>
          </div>
          {error && (
            <p className="m-0 mt-3">
              {error}
              <br />
              You own {calculateDrkCreditsBalance(usersData?.offChainDRK)} DRK credits
            </p>
          )}
        </>
      )}
    </div>
  );
};

export { Cart };
