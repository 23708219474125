import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { Zoom } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const SuccessModalUnisat = ({ open, setOpen, transactionId }) => {
  return (
    <Dialog
      data-testid="transaction-success-dialog"
      open={open}
      TransitionComponent={Transition}
      slotProps={{
        backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
      }}
      PaperProps={{ style: { backgroundColor: '#16151a', padding: '40px 80px 25px 80px', maxWidth: '520px' } }}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <button
        className="modal__close"
        type="button"
        style={{ top: '37px', right: '37px' }}
        onClick={() => setOpen(false)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
        </svg>
      </button>
      <div className="d-flex align-items-center justify-content-center flex-column gap-3 px-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100"
          height="100"
          viewBox="0 0 50 50"
          fill="#8CC84B"
        >
          <path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 34.988281 14.988281 A 1.0001 1.0001 0 0 0 34.171875 15.439453 L 23.970703 30.476562 L 16.679688 23.710938 A 1.0001 1.0001 0 1 0 15.320312 25.177734 L 24.316406 33.525391 L 35.828125 16.560547 A 1.0001 1.0001 0 0 0 34.988281 14.988281 z"></path>
        </svg>
        <h4 className="sign__title" style={{ color: '#8CC84B' }}>
          Transaction successful
        </h4>

        <p className="modal__text mt-2 fs-5">
          Follow up on{' '}
          <a
            href={`https://mempool.space/tx/${transactionId}`}
            target="_blank"
            rel="noreferrer"
            style={{ fontWeight: 'bold' }}
          >
            mempool
          </a>
        </p>
      </div>
    </Dialog>
  );
};

export { SuccessModalUnisat };
