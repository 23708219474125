import Carousel from 'react-bootstrap/Carousel';
import hero1 from './img/hero1.jpg';
import hero2 from './img/hero2.jpg';

const AVATARS_LINK =
  'https://magiceden.io/ordinals/marketplace/drklist?attributes=%257B%2522Type%2522%253A%255B%257B%2522traitType%2522%253A%2522Type%2522%252C%2522value%2522%253A%2522avatar%2522%252C%2522count%2522%253A1337%252C%2522floor%2522%253A159000%252C%2522image%2522%253A%2522https%253A%252F%252Fimg-cdn.magiceden.dev%252Frs%253Afill%253A400%253A0%253A0%252Fplain%252Fhttps%253A%252F%252Fdarkcity.infura-ipfs.io%252Fipfs%252FQmZzyeH9eTVvDKrxE5sCzM7eTaTTULuRWRcSx7MXCi5EgG%2522%252C%2522label%2522%253A%2522avatar%2522%252C%2522listedCount%2522%253A0%252C%2522total%2522%253A0%257D%255D%257D';

const BS_Carousel = () => {
  return (
    <Carousel
      data-bs-theme="dark"
      prevIcon={
        <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" fill="white" width="16px">
          <path d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z" />
        </svg>
      }
      nextIcon={
        <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" fill="white" width="16px">
          <path d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z" />
        </svg>
      }
    >
      <Carousel.Item>
        <img
          className="d-block w-100 resp-gallery-image"
          src={hero1}
          alt="First slide"
          height="360px"
          style={{
            filter: 'brightness(0.42)',
          }}
        />
        <div className="carousel-content overlay">
          <h1 className="hero__title">The inaugural TAP native marketplace</h1>
          <p className="hero__text">
            The Moon Runners have unveiled their market. Explore numerous opportunities to amass power in Dark City
            using the $DRK token.
          </p>
          <div className="hero__btns">
            <a
              href="https://manual.dark.city/dark-market/"
              className="hero__btn hero__btn--clr"
              target="_blank"
              rel="noreferrer"
            >
              How it works
            </a>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 resp-gallery-image"
          src={hero2}
          alt="Second slide"
          height="360px"
          style={{
            filter: 'brightness(0.33)',
          }}
        />
        <div className="carousel-content">
          <h1 className="hero__title">Generation 1 Avatars</h1>
          <p className="hero__text">
            Integrate software, hardware, and wearables with your Avatar to enhance your performance in the upcoming
            Dark City Season 1: Net Scavengers.
          </p>
          <div className="hero__btns">
            <a href={AVATARS_LINK} className="hero__btn hero__btn--clr" target="_blank" rel="noreferrer">
              Get an Avatar
            </a>
            <a
              href="https://manual.dark.city/avatars-and-gameplay/"
              className="hero__btn"
              target="_blank"
              rel="noreferrer"
              style={{
                borderRadius: '5px',
                boxShadow: '0 0 10px rgba(255, 255, 255, 0.6)',
              }}
            >
              Learn More
            </a>
          </div>
        </div>
      </Carousel.Item>
      {/*
      <Carousel.Item>
        <img className="d-block w-100 resp-gallery-image" src={slide3} alt="Third slide" height="360px" />
        <div className="carousel-content">
          <h1 className="hero__title">Exclusive digital asset</h1>
          <p className="hero__text">
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in
            some form, by injected humour.
          </p>
          <div className="hero__btns">
            <a href="item2.html" className="hero__btn hero__btn--clr">
              More details
            </a>
            <a href="item2.html" className="hero__btn">
              Place a bid
            </a>
          </div>
        </div>
      </Carousel.Item> */}
    </Carousel>
  );
};

export { BS_Carousel };
