import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useStore } from '../../store';

export const isItemInCart = (cart, item) => {
  return cart.find((_item) => _item?.id === item.id);
};

const AddToCartToggle = ({ item, setOpen }) => {
  const {
    auth: { status },
    cart,
    addToCart,
    removeFromCart,
  } = useStore();

  return (
    <>
      {isItemInCart(cart, item) ? (
        <OverlayTrigger placement="left" overlay={<Tooltip id="convert-tooltip">Remove from cart</Tooltip>}>
          <button
            onClick={() => {
              if (status !== 'authenticated') {
                setOpen(true);
                return;
              }
              removeFromCart(item);
            }}
            aria-label="Remove from cart"
          >
            <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21 5L19 12H7.37671M20 16H8L6 3H3M16 6H11M9 20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z"
                stroke="#6164ff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger placement="left" overlay={<Tooltip id="convert-tooltip">Add to cart</Tooltip>}>
          <button
            onClick={() => {
              if (status !== 'authenticated') {
                setOpen(true);
                return;
              }
              addToCart(item);
            }}
            aria-label="Add to cart"
          >
            <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21 5L19 12H7.37671M20 16H8L6 3H3M16 5.5H13.5M13.5 5.5H11M13.5 5.5V8M13.5 5.5V3M9 20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z"
                stroke="#6164ff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </OverlayTrigger>
      )}
    </>
  );
};

export { AddToCartToggle };
