import { useMutation, useQueryClient } from '@tanstack/react-query';

import { v4 as uuidv4 } from 'uuid';
import { useStore } from '../store';
import { BASE_URL } from '../../config';

const randomMessage = uuidv4();

const signMessage = async (randomMessage) => {
  try {
    let signature = await window.unisat.signMessage(randomMessage);
    return signature;
  } catch (e) {
    console.log(e);
  }
};

const useUnisatAuth = () => {
  const { setAuth } = useStore();
  const queryClient = useQueryClient();

  const authMutation = useMutation({
    mutationKey: ['auth'],
    mutationFn: async ({ publicKey, address }) => {
      const signature = await signMessage(randomMessage);

      const body = { publicKey: publicKey, message: randomMessage, signature: signature, address: address };

      const response = await fetch(`${BASE_URL}/users`, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (response.status === 200) {
        const responseData = await response.json();
        return { res: responseData, address: address, statusCode: response.status };
      } else if (response.status === 401) {
        const responseData = await response.json();
        return { res: responseData, address: null, statusCode: response.status };
      } else {
        throw new Error('Failed to check auth status');
      }
    },
    onSuccess: (data) => {
      if (data.res?.success === 'Authentication success') {
        setAuth({ status: 'authenticated', address: data.address, walletType: 'unisat', sessionExpired: false });
      } else {
        setAuth({ status: 'not-authenticated', address: null });
      }
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['orders']);
      queryClient.invalidateQueries(['offchainItems']);
      queryClient.invalidateQueries(['inventory']);
      queryClient.invalidateQueries(['stakeData']);
      queryClient.invalidateQueries(['quizData']);
    },
  });

  return authMutation;
};

export { useUnisatAuth, signMessage };
