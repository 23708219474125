const buildUrl = (filters, baseUrl) => {
  if (Object.keys(filters).length === 0) return baseUrl;

  const typeFilters = Object.entries(filters.type)
    .filter(([, value]) => value)
    .map(([key]) => `type[]=${encodeURIComponent(key)}`)
    .join('&');

  const rarityFilters = Object.entries(filters.rarity)
    .filter(([, value]) => value)
    .map(([key]) => `rarity[]=${encodeURIComponent(key)}`)
    .join('&');

  const queryParams = [typeFilters, rarityFilters].filter(Boolean).join('&');
  return `${baseUrl}?${queryParams}`;
};

const fetchData = async (url) => {
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

export const applySearching = (items, searchingPhrase) => {
  if (!searchingPhrase || searchingPhrase?.length === 0) return items;
  if (!items) return;

  const dataAfterSearching = items.filter((item) => {
    const keys = Object.keys(item);

    return keys.some((key) => {
      const value = item[key];
      if (typeof value === 'string') {
        return value.toLowerCase().includes(searchingPhrase.toLowerCase());
      }
      return false;
    });
  });

  return dataAfterSearching;
};

export { buildUrl, fetchData };
