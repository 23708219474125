import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../../config';

const useBuyMutation = (itemId, itemToBuy) => {
  const queryClient = useQueryClient();

  const buyMutation = useMutation({
    mutationKey: ['buy', itemId],
    mutationFn: async () => {
      const body = { price: itemToBuy?.price };

      const response = await fetch(`${BASE_URL}/orders/${itemId}`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Failed to execute buy operation');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['clusteredItems']);
      queryClient.invalidateQueries(['grouped']);
      queryClient.invalidateQueries(['ungrouped']);
      queryClient.invalidateQueries(['item', itemId]);
    },
  });

  return buyMutation;
};
export { useBuyMutation };
