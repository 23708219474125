import { useQueries } from '@tanstack/react-query';
import instruction1 from './instructionPhotos/1.png';
import instruction2 from './instructionPhotos/2.png';
import instruction3 from './instructionPhotos/3.webp';

const fetchData = async (id) => {
  const url = `https://ordinals.com/content/${id}`;
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

const XverseInstructions = ({ setOpen, data }) => {
  const combinedQueries = useQueries({
    queries: data.map((item) => ({
      queryKey: ['ordinalsData', item.inscriptionId],
      queryFn: () => fetchData(item.inscriptionId),
    })),
    combine: (results) => {
      return {
        data: results.map((result, index) => ({
          amt: result.data?.amt,

          // Jeśli API trac się zjebało, to to też nie zadziała
          // Bo indeksy mogą być nie-aktualne

          inscriptionId: data[index].inscriptionId,
          walletAddress: data[index].walletAddress,
          feeRate: data[index].feeRate,
        })),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  return (
    <>
      <button
        className="modal__close"
        type="button"
        style={{ top: '17px', right: '17px' }}
        onClick={() => setOpen(false)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
        </svg>
      </button>

      <label className="filter__label text-center fs-4 mb-0">Convert to DRK credits</label>
      {combinedQueries?.data?.length > 0 ? (
        <div className="d-flex" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ color: 'white', textAlign: 'center', width: '100%', marginTop: '40px', marginBottom: '0px' }}>
            Step 1. Open your xverse wallet and select inscription you want to send. It should have operation type of{' '}
            <b>token-transfer</b>
          </p>
          <img
            src={instruction1}
            alt=""
            width={400}
            height={700}
            style={{
              outline: '1px solid white',
              marginTop: '12px',
              maxWidth: '70%',
              height: 'auto',
            }}
          ></img>
          <p style={{ color: 'white', textAlign: 'center', width: '100%', marginTop: '40px', marginBottom: '0px' }}>
            Step 2. Click send
          </p>
          <img
            src={instruction2}
            alt=""
            width={400}
            height={700}
            style={{
              outline: '1px solid white',
              marginTop: '12px',
              maxWidth: '70%',
              height: 'auto',
            }}
          ></img>
          <p style={{ color: 'white', textAlign: 'center', width: '100%', marginTop: '40px', marginBottom: '0px' }}>
            Step 3. Enter our internal Dark City wallet as recipient address <br />
            <b>bc1qh32szyy5ly8c6s0ecdmffxwqp3j5ey59qxxlca</b>
          </p>
          <img
            src={instruction3}
            alt=""
            width={400}
            height={700}
            style={{
              outline: '1px solid white',
              marginTop: '12px',
              maxWidth: '70%',
              height: 'auto',
            }}
          ></img>
          <p style={{ color: 'white', textAlign: 'center', width: '100%', marginTop: '40px', marginBottom: '0px' }}>
            Step 4. It is recommended to adjust fee rate. We suggest {combinedQueries?.data[0].feeRate} fee rate for
            this moment
          </p>
          <p style={{ color: 'white', textAlign: 'center', width: '100%', marginTop: '40px', marginBottom: '0px' }}>
            Step 5. Confirm transaction and wait until it is fullfilled
          </p>
        </div>
      ) : (
        <div className="text-white text-center mt-2">
          You don&rsquo;t have any $DRK transfer inscriptions to convert to DRK credits.
        </div>
      )}
    </>
  );
};

export { XverseInstructions };
