import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CopyToClipboardButton } from '../../components/copy-to-clipboard-button';
import { Header, formatBitcoinAddress } from '../../components/header';
import avatar from './assets/avatar5.jpg';
import background from './assets/background.jpg';
import { useProfileDataQuery } from './hooks/useProfileDataQuery';
import { Listings } from './listings';
import { LoadingUser } from './loading';
import { OfficialItems } from './official-items';
import { UnofficialItems } from './unofficial-items';
import { UserNotFound } from './user-not-found';

const UserProfile = () => {
  const { pathname } = useLocation();
  const { walletId } = useParams();

  const [disablePointerEvents, setDisablePointerEvents] = useState(false);

  // to prevent displaying two tabs, when we click fast
  const handleClick = () => {
    setDisablePointerEvents(true);

    setTimeout(() => {
      setDisablePointerEvents(false);
    }, 400);
  };

  const [searchingPhrase, setSearchingPhrase] = useState('');

  const isNotMintedTabActive = pathname.includes(`users/${walletId}/off-chain`);
  const isMintedTabActive = pathname.includes(`users/${walletId}/on-chain`);
  const isListingsTabActive = pathname.includes(`users/${walletId}/listings`);

  const profileDataResults = useProfileDataQuery(walletId);
  const { data, isLoading, isError } = profileDataResults;
  const { walletAddress, name, backgroundImg, profilePicture } = data || [];

  const userNotFound = data?.error === 'User with the specified wallet ID was not found' || isError;

  const [displayLoadingState, setDisplayLoadingState] = useState(true);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setDisplayLoadingState(false);
    }, 500);

    return () => clearTimeout(loadingTimeout);
  }, []);

  return (
    <>
      <Header searchingPhrase={searchingPhrase} setSearchingPhrase={setSearchingPhrase} />
      {userNotFound && !displayLoadingState ? (
        <div style={{ minHeight: '92dvh', paddingTop: '300px' }}>
          <UserNotFound />
        </div>
      ) : displayLoadingState || isLoading ? (
        <div style={{ minHeight: '92dvh', paddingTop: '300px' }}>
          <LoadingUser />
        </div>
      ) : (
        <main className="main" style={{ minHeight: '105dvh' }}>
          <img className="main__author" src={backgroundImg ? backgroundImg : background} alt="" />
          <div className="container">
            <div className="row row--grid">
              <div className="col-12 col-xl-3">
                <div className="author author--page">
                  <div className="author__meta">
                    <a className="author__avatar author__avatar--verified">
                      <img
                        src={profilePicture ? profilePicture : avatar}
                        alt=""
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    </a>
                    <h1 className="author__name mb-2">
                      <a>{name ? name : 'Username'}</a>
                    </h1>
                    <div className="d-flex gap-2 align-items-center" style={{ lineHeight: '22px' }}>
                      <p className="profile__paragraph mb-0">{formatBitcoinAddress(walletAddress)}</p>
                      <CopyToClipboardButton textToCopy={walletAddress} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-9">
                <div className="profile">
                  {/* tabs nav */}
                  <ul
                    className="nav nav-tabs profile__tabs"
                    id="profile__tabs"
                    role="tablist"
                    style={{ pointerEvents: disablePointerEvents ? 'none' : 'auto' }}
                  >
                    <li className="nav-item">
                      <a
                        className={isNotMintedTabActive ? 'active' : ''}
                        aria-selected={isNotMintedTabActive ? 'true' : 'false'}
                        data-toggle="tab"
                        href="#tab-1"
                        role="tab"
                        aria-controls="tab-1"
                        onClick={handleClick}
                      >
                        <Link to={`/users/${walletId}/off-chain/grouped/1`} style={{ color: 'inherit' }}>
                          Off-chain items
                        </Link>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={isMintedTabActive ? 'active' : ''}
                        aria-selected={isMintedTabActive ? 'true' : 'false'}
                        data-toggle="tab"
                        href="#tab-2"
                        role="tab"
                        aria-controls="tab-2"
                        onClick={handleClick}
                      >
                        <Link to={`/users/${walletId}/on-chain/1`} style={{ color: 'inherit' }}>
                          On-chain items
                        </Link>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={isListingsTabActive ? 'active' : ''}
                        aria-selected={isListingsTabActive ? 'true' : 'false'}
                        data-toggle="tab"
                        href="#tab-3"
                        role="tab"
                        aria-controls="tab-3"
                        onClick={handleClick}
                      >
                        <Link to={`/users/${walletId}/listings`} style={{ color: 'inherit' }}>
                          Listings
                        </Link>
                      </a>
                    </li>
                  </ul>
                  {/* end tabs nav */}
                </div>
                {/* content tabs */}
                <div className="tab-content">
                  <div
                    className={`tab-pane fade${isNotMintedTabActive ? ' show active fade-in' : ''}`}
                    id="tab-1"
                    role="tabpanel"
                  >
                    <UnofficialItems profileDataResults={profileDataResults} />
                  </div>
                  <div
                    className={`tab-pane fade${isMintedTabActive ? ' show active fade-in' : ''}`}
                    id="tab-2"
                    role="tabpanel"
                  >
                    <OfficialItems searchingPhrase={searchingPhrase} profileDataResults={profileDataResults} />
                  </div>
                  <div
                    className={`tab-pane fade${isListingsTabActive ? ' show active fade-in' : ''}`}
                    id="tab-3"
                    role="tabpanel"
                  >
                    <div className="row">
                      <Listings profileDataResults={profileDataResults} />
                    </div>
                  </div>
                </div>
              </div>
              {/* end content tabs */}
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export { UserProfile };
