import { Zoom } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { useState } from 'react';
import { useGiftDrk } from '../../pages/your-inventory/unofficial-items/useGiftDrk';
import { useStore } from '../../store';
import { SuccessModal } from '../success-modal';
import { validate } from 'bitcoin-address-validation';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const GiftDrkModal = ({ setOpen, open, usersData }) => {
  const [value, setValue] = React.useState('');
  const [receiverWalletAddress, setReceiverWalletAddress] = useState('');
  const [error, setError] = React.useState('');
  const {
    auth: { status },
  } = useStore();

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleReceiverAddressChange = (e) => {
    setReceiverWalletAddress(e.target.value);
  };

  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const giftDrkMutation = useGiftDrk();

  const giftDrk = () => {
    const addressNotValid = !validate(receiverWalletAddress);

    if (!value || value === '0' || +value < 0) {
      setError('Please select amount');
      return;
    }
    if (!receiverWalletAddress) {
      setError('Please select recipient wallet address');
      return;
    }
    if (usersData?.offChainDRK / 10 ** 18 < Number(value)) {
      setError('You cannot gift more DRK than you own!');
      return;
    }
    if (addressNotValid) {
      setError(`Please select correct recipient wallet address`);
      return;
    }

    setError('');

    giftDrkMutation.mutate(
      { amount: parseFloat(value, 10), walletAddress: receiverWalletAddress },
      {
        onSuccess: async () => {
          setOpenSuccessModal(true);
        },
      }
    );

    setOpen(false);
  };

  return (
    <>
      <SuccessModal open={openSuccessModal} setOpen={setOpenSuccessModal}>
        <h4 className="sign__title" style={{ color: 'white', fontWeight: 'normal' }}>
          DRK credits gifted successfully
        </h4>
      </SuccessModal>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
        }}
        PaperProps={{
          style: { backgroundColor: '#16151a', padding: '80px 80px 25px 80px', width: '550px' },
        }}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        {status === 'authenticated' && Number(usersData?.offChainDRK) === 0 && (
          <>
            <button
              className="modal__close"
              type="button"
              style={{ top: '37px', right: '37px' }}
              onClick={() => setOpen(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
              </svg>
            </button>
            <div className="text-white text-center">You don&rsquo;t have any $DRK credits to gift</div>
            <button
              className="sign__btn mt-4"
              style={{ maxWidth: '120px' }}
              type="button"
              onClick={() => setOpen(false)}
            >
              OK
            </button>
          </>
        )}
        {status === 'authenticated' && usersData?.offChainDRK > 0 && (
          <>
            <button
              className="modal__close"
              type="button"
              style={{ top: '37px', right: '37px' }}
              onClick={() => setOpen(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
              </svg>
            </button>
            <div className="filter__group align-items-center">
              <label className="filter__label">Select amount of $DRK credits to gift</label>
              <input
                type="number"
                className="filter__input text-center"
                value={value}
                onChange={handleInputChange}
                data-testid="drk-gift-amount-input"
              />
            </div>
            <div className="filter__group align-items-center">
              <label className="filter__label" htmlFor="receiverWallet">
                Choose the recipient&rsquo;s wallet address.
              </label>
              <input
                id="receiverWallet"
                type="text"
                className="filter__input text-center"
                value={receiverWalletAddress}
                onChange={handleReceiverAddressChange}
                data-testid="drk-gift-receiver-input"
              />
            </div>
            {error && <div className="text-white text-center">{error}</div>}
            <button className="sign__btn mt-4" style={{ maxWidth: '120px' }} type="button" onClick={giftDrk}>
              Gift
            </button>
          </>
        )}
        {status === 'not-authenticated' && (
          <>
            <button
              className="modal__close"
              type="button"
              style={{ top: '37px', right: '37px' }}
              onClick={() => setOpen(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
              </svg>
            </button>

            <label className="filter__label fs-5 text-center">You need to connect to your wallet first!</label>

            <button
              className="sign__btn mt-4"
              style={{ maxWidth: '120px', height: '40px' }}
              type="button"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
          </>
        )}
      </Dialog>
    </>
  );
};

export { GiftDrkModal };
