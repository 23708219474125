import { useParams } from 'react-router-dom';
import { UnofficialItemsGrouped } from './unofficial-items-grouped';
import { UnofficialItemsUngrouped } from './unofficial-items-ungrouped';

const UnofficialItems = ({ walletAddress, profileUri }) => {
  const { groupingType } = useParams();

  return (
    <>
      {groupingType === 'grouped' && <UnofficialItemsGrouped walletAddress={walletAddress} profileUri={profileUri} />}
      {groupingType === 'ungrouped' && (
        <UnofficialItemsUngrouped walletAddress={walletAddress} profileUri={profileUri} />
      )}
    </>
  );
};

export { UnofficialItems };
