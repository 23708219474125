import { useMutation, useQueryClient } from '@tanstack/react-query';

import { v4 as uuidv4 } from 'uuid';
import { useStore } from '../store';
import { signMessage } from 'sats-connect';
import { BASE_URL } from '../../config';

const randomMessage = uuidv4();

export const signMessagexVerse = async (address, randomMessage) => {
  return new Promise((resolve, reject) => {
    signMessage({
      payload: {
        network: {
          type: 'Mainnet',
        },
        address: address,
        message: randomMessage,
      },
      onFinish: (response) => {
        resolve(response); // Resolve the promise with the response
      },
      onCancel: () => {
        alert('Canceled');
        reject(new Error('Signing canceled')); // Reject the promise with an error
      },
    });
  });
};

const useXverseAuth = () => {
  const { setAuth } = useStore();
  const queryClient = useQueryClient();

  const authMutation = useMutation({
    mutationKey: ['auth xverse'],
    mutationFn: async ({ publicKey, address }) => {
      const signature = await signMessagexVerse(address, randomMessage);
      const body = {
        publicKey: publicKey,
        message: randomMessage,
        signature: signature,
        address: address,
        vendor: 'xverse',
      };

      const response = await fetch(`${BASE_URL}/users`, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (response.status === 200) {
        const responseData = await response.json();
        return { res: responseData, address: address, statusCode: response.status };
      } else if (response.status === 401) {
        const responseData = await response.json();
        return { res: responseData, address: null, statusCode: response.status };
      } else {
        throw new Error('Failed to check auth status');
      }
    },
    onSuccess: (data) => {
      if (data.res?.success === 'Authentication success') {
        setAuth({ status: 'authenticated', address: data.address, walletType: 'xverse', sessionExpired: false });
      } else {
        setAuth({ status: 'not-authenticated', address: null });
      }
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['orders']);
      queryClient.invalidateQueries(['offchainItems']);
      queryClient.invalidateQueries(['inventory']);
      queryClient.invalidateQueries(['stakeData']);
      queryClient.invalidateQueries(['quizData']);
    },
  });

  return authMutation;
};

export { useXverseAuth };
