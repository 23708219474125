import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import routes from './Router/index';
import { useSessionExpired } from './auth/useSessionExpired';
import Footer from './components/footer';
import { WarningModal } from './components/warning-modal';
// import { useStore } from './store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30000,
    },
  },
});

function App() {
  // const store = useStore();

  // const allUnisat = useUnisat();

  // console.log('store', store);

  // const allxVerse = UseXverse();
  // console.log('allxVerse', allxVerse);

  // console.log('allUnisat', allUnisat);

  const [sessionExpiredModalOpen, setSessionExpiredModalOpen] = useState(false);

  useSessionExpired(setSessionExpiredModalOpen);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <WarningModal open={sessionExpiredModalOpen} setOpen={setSessionExpiredModalOpen}>
        <h4 className="sign__title" style={{ color: 'white', fontWeight: 'normal' }}>
          Your session has expired. Please log in.
        </h4>
      </WarningModal>
      <Routes>
        {routes.map((data, index) => (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={data.component}
            key={index}
            index={data.isIndex}
          />
        ))}
      </Routes>
      <Footer />
    </QueryClientProvider>
  );
}

export default App;
