import { Zoom } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { useDelistItem } from '../../pages/your-inventory/unofficial-items/useDelistItem';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const DelistingItemModal = ({ setOpen, open, itemId }) => {
  const delistMutation = useDelistItem(itemId);

  const listItem = () => {
    delistMutation.mutate(
      {},
      {
        onSuccess: async () => {
          console.log('Delisting successfull');
        },
      }
    );

    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
        }}
        PaperProps={{
          style: { backgroundColor: '#16151a', padding: '80px 80px 25px 80px', width: '500px' },
        }}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <>
          <button
            className="modal__close"
            type="button"
            style={{ top: '37px', right: '37px' }}
            onClick={() => setOpen(false)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
            </svg>
          </button>
          <p className="filter__group align-items-center text-white fs-6 text-center">
            Are you sure you want to delist this item?
          </p>
          <button className="sign__btn mt-4" style={{ maxWidth: '120px' }} type="button" onClick={listItem}>
            Delist
          </button>
        </>
      </Dialog>
    </>
  );
};

export { DelistingItemModal };
