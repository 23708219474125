import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { AddressPurpose, BitcoinNetworkType, getAddress, getCapabilities } from 'sats-connect';

const UseXverse = () => {
  const [paymentAddress, setPaymentAddress] = useLocalStorage('paymentAddress');
  const [paymentPublicKey, setPaymentPublicKey] = useLocalStorage('paymentPublicKey');
  const [ordinalsAddress, setOrdinalsAddress] = useLocalStorage('ordinalsAddress');
  const [ordinalsPublicKey, setOrdinalsPublicKey] = useLocalStorage('ordinalsPublicKey');
  const [network, setNetwork] = useLocalStorage('network', BitcoinNetworkType.Mainnet);
  const [capabilityState, setCapabilityState] = useState('loading');
  const [capabilities, setCapabilities] = useState();

  useEffect(() => {
    const runCapabilityCheck = async () => {
      const MAX_RUNS = 15;
      setCapabilityState('loading');

      // the wallet's in-page script may not be loaded yet, so we'll try a few times
      for (let i = 0; i <= MAX_RUNS; ++i) {
        try {
          await getCapabilities({
            onFinish(response) {
              setCapabilities(new Set(response));
              setCapabilityState('loaded');
            },
            onCancel() {
              setCapabilityState('cancelled');
            },
            payload: {
              network: {
                type: network,
              },
            },
          });
        } catch (e) {
          if (i === MAX_RUNS) {
            setCapabilityState('missing');
          }
        }
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
    };

    runCapabilityCheck();
  }, [network]);

  const isReady = !!paymentAddress && !!paymentPublicKey && !!ordinalsAddress && !!ordinalsPublicKey;

  const onWalletDisconnect = () => {
    setPaymentAddress(undefined);
    setPaymentPublicKey(undefined);
    setOrdinalsAddress(undefined);
    setOrdinalsPublicKey(undefined);
  };

  const toggleNetwork = () => {
    setNetwork(network === BitcoinNetworkType.Testnet ? BitcoinNetworkType.Mainnet : BitcoinNetworkType.Testnet);
    onWalletDisconnect();
  };

  const onConnectClick = async () => {
    await getAddress({
      payload: {
        purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
        message: 'Approve connection to Xverse wallet and sign message',
        network: {
          type: network,
        },
      },
      onFinish: (response) => {
        const paymentAddressItem = response.addresses.find((address) => address.purpose === AddressPurpose.Payment);
        setPaymentAddress(paymentAddressItem?.address);
        setPaymentPublicKey(paymentAddressItem?.publicKey);

        const ordinalsAddressItem = response.addresses.find((address) => address.purpose === AddressPurpose.Ordinals);
        setOrdinalsAddress(ordinalsAddressItem?.address);
        setOrdinalsPublicKey(ordinalsAddressItem?.publicKey);
      },
      onCancel: () => alert('Request canceled'),
    });
  };

  const capabilityMessage =
    capabilityState === 'loading'
      ? 'Checking capabilities...'
      : capabilityState === 'cancelled'
        ? 'Capability check cancelled by wallet. Please refresh the page and try again.'
        : capabilityState === 'missing'
          ? 'Could not find an installed Sats Connect capable wallet. Please install a wallet and try again.'
          : !capabilities
            ? 'Something went wrong with getting capabilities'
            : undefined;

  return {
    paymentAddress,
    ordinalsAddress,
    paymentPublicKey,
    ordinalsPublicKey,
    isReady,
    toggleNetwork,
    onConnectClick,
    capabilityState,
    capabilities,
    network,
    capabilityMessage,
  };
};

export { UseXverse };
