import { useQuery } from '@tanstack/react-query';
import { BASE_URL } from '../../../../config';

const useCustomProfileDataQuery = (userPostfix) => {
  const userProfileResults = useQuery({
    queryKey: ['customProfile', userPostfix],
    queryFn: async () => {
      const response = await fetch(`${BASE_URL}/users/custom/${userPostfix}`);

      return response.json();
    },
  });

  return userProfileResults;
};

export { useCustomProfileDataQuery };
