import * as React from 'react';
import { Zoom } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useQuery } from '@tanstack/react-query';
import { fetchData } from '../../pages/all-items/utils';
import { useBuyMutation } from './useBuyRequest';
import { useNavigate } from 'react-router-dom';
import { useGetUsers } from '../../pages/your-inventory/useGetUsers';
import { useState } from 'react';
import { BASE_URL } from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const getFirstItemId = (data) => {
  if (data) return data[0].id;
};

const BuyItemModal = ({ handleClose, open, itemToBuy, itemId }) => {
  const url = `${BASE_URL}/` + itemToBuy?.resourceUri;

  const navigate = useNavigate();
  const [success, setSuccess] = React.useState(false);

  // We dont have inscription on all-items screen, so we have to fetch again...
  const grupedItemsResult = useQuery({
    queryKey: ['clustered-items', itemToBuy?.name],
    queryFn: () => fetchData(url),
    select: (data) => {
      return data.map((item) => ({
        id: item.id,
        name: item.meta.name,
        type: item.meta.type,
        image: item.meta.image,
        rarity: item.meta.rarity,
        description: item.meta.description,
        inscription_id: item.inscription_id,
      }));
    },
    enabled: !!itemToBuy?.resourceUri,
  });
  const { data } = grupedItemsResult;

  const id = itemId || itemToBuy?.id || getFirstItemId(data);

  const buyMutation = useBuyMutation(id, itemToBuy);

  const result = useGetUsers();
  const { data: usersData } = result;

  const [feedbackInfo, setFeedbackInfo] = useState('');

  const buyItem = () => {
    const balance = Number(usersData?.offChainDRK / 10 ** 18);
    const price = Number(itemToBuy?.price);
    const notSufficientBalance = price > balance;

    if (notSufficientBalance) {
      setFeedbackInfo('Not sufficient balance of DRK credits');
      return;
    }

    buyMutation.mutate(
      {},
      {
        onSuccess: () => {
          setSuccess(true);
          setFeedbackInfo('');
          setTimeout(() => {
            navigate('/your-inventory/not-minted/grouped/1');
            handleClose();
          }, 2000);
        },
      }
    );
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
        }}
        PaperProps={{ style: { backgroundColor: '#16151a', padding: '40px 40px 25px 40px', maxWidth: '520px' } }}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <button className="modal__close" type="button" style={{ top: '37px', right: '37px' }} onClick={handleClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
          </svg>
        </button>
        {success ? (
          <div className="d-flex align-items-center justify-content-center flex-column gap-3 px-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 50 50"
              fill="#8CC84B"
            >
              <path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 34.988281 14.988281 A 1.0001 1.0001 0 0 0 34.171875 15.439453 L 23.970703 30.476562 L 16.679688 23.710938 A 1.0001 1.0001 0 1 0 15.320312 25.177734 L 24.316406 33.525391 L 35.828125 16.560547 A 1.0001 1.0001 0 0 0 34.988281 14.988281 z"></path>
            </svg>
            <h4 className="sign__title" style={{ color: '#8CC84B' }}>
              Success
            </h4>

            <p className="modal__text mt-2 fs-5">Redirecting to your inventory...</p>
          </div>
        ) : (
          <>
            <h4 className="sign__title">Buy item</h4>
            <p className="modal__text mt-2">
              Are you sure you want to buy <b className="text-white">{itemToBuy?.name}</b> for {itemToBuy?.price} DRK?
            </p>
            {itemToBuy?.image && (
              <img
                src={`https://darkcity.infura-ipfs.io/ipfs/${itemToBuy?.image}`}
                alt=""
                width={240}
                height={240}
                style={{ border: '20px solid #222227', alignSelf: 'center' }}
              />
            )}
            <button className="sign__btn mt-4" type="button" onClick={buyItem}>
              Buy
            </button>
            {feedbackInfo && (
              <div className="text-white text-center" role="status" aria-live="polite">
                {feedbackInfo}
              </div>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export { BuyItemModal };
