import { useQuery } from '@tanstack/react-query';
import { BASE_URL } from '../../../../config';

const useStakeHistory = () => {
  const stakeHistory = useQuery({
    queryKey: ['stakeHistory'],
    queryFn: async () => {
      const response = await fetch(`${BASE_URL}/stake/history`, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch stake history');
      }

      return response.json();
    },
  });

  return stakeHistory;
};

export { useStakeHistory };
