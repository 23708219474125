import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const useMintingConfirmedUnisat = () => {
  const queryClient = useQueryClient();

  const mintItemConfirmedUnisat = useMutation({
    mutationKey: ['mintItemConfirmedUnisat'],
    mutationFn: async ({ id, orderId, txId }) => {
      const body = { txId: txId, orderId: orderId };

      const response = await fetch(`${BASE_URL}/items/${id}/mint`, {
        method: 'PUT',
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to send tx confirmation from unisat wallet');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['offchainItems']);
    },
  });

  return mintItemConfirmedUnisat;
};

export { useMintingConfirmedUnisat };
