import { useMutation } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const useMintItemXverse = () => {
  const mintItemXverse = useMutation({
    mutationKey: ['mintItemXverse'],
    mutationFn: async (id) => {
      const response = await fetch(`${BASE_URL}/items/${id}/mint/xverse`, {
        method: 'POST',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to mint item Xverse');
      }

      return response.json();
    },
  });

  return mintItemXverse;
};

export { useMintItemXverse };
