import { useEffect } from 'react';

const useInitializeFilters = (result, setFilters, filters) => {
  let { data, error, isLoading } = result;

  useEffect(() => {
    if (!isLoading && !error && data !== undefined) {
      const uniqueRarities = [
        ...new Set(
          data.map((item) => {
            // One endpoint returns item on .meta key, other not
            if (item.meta && item.meta.rarity) {
              return item.meta.rarity;
            } else {
              return item.rarity;
            }
          })
        ),
      ];
      const uniqueTypes = [
        ...new Set(
          data.map((item) => {
            // One endpoint returns item on .meta key, other not
            if (item.meta && item.meta.type) {
              return item.meta.type;
            } else {
              return item.type;
            }
          })
        ),
      ];

      for (const type of uniqueTypes) {
        if (!Object.keys(filters.type).some((category) => category === type) && type) {
          setFilters((prev) => ({ ...prev, type: { ...prev.type, [type]: false } }));
        }
      }
      for (const rarity of uniqueRarities) {
        if (!Object.keys(filters.rarity).some((category) => category === rarity) && rarity) {
          setFilters((prev) => ({ ...prev, rarity: { ...prev.rarity, [rarity]: false } }));
        }
      }
    }
  }, [setFilters, isLoading, data, error, filters.rarity, filters.type]);
};
export { useInitializeFilters };
