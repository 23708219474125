import { useQuery } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const fetchData = async (url) => {
  const response = await fetch(url, {
    credentials: 'include',
  });
  const data = await response.json();
  return data;
};

const useGetUnofficialItems = (walletAddress) => {
  const url = `${BASE_URL}/users/${walletAddress}/items/offchain/groups`;

  const userItems = useQuery({
    queryKey: ['offchainItems', walletAddress],
    queryFn: () => fetchData(url),
  });

  return userItems;
};
export { useGetUnofficialItems };
