const Fallback = ({ text }) => {
  return (
    <div>
      <main className="main" style={{ minHeight: '31dvh' }}>
        <div className="main__title main__title--page">
          <h2>{text}</h2>
        </div>
      </main>
    </div>
  );
};
export { Fallback };
