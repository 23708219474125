import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { Zoom } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const StakingProgressModal = ({ open, setOpen, children }) => {
  return (
    <Dialog
      data-testid="transaction-success-dialog"
      open={open}
      TransitionComponent={Transition}
      slotProps={{
        backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
      }}
      PaperProps={{ style: { backgroundColor: '#16151a', padding: '40px 80px 25px 80px', maxWidth: '600px' } }}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <button
        className="modal__close"
        type="button"
        style={{ top: '37px', right: '37px' }}
        onClick={() => setOpen(false)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
        </svg>
      </button>
      <div className="d-flex align-items-center justify-content-center flex-column gap-3 px-4">
        <svg height="100px" width="100px" version="1.1" id="Capa_1" viewBox="0 0 363.868 363.868" xmlSpace="preserve">
          <g>
            <path
              fill="#6164ff"
              d="M92.723,274.945c-3.178,3.178-5.747,9.388-5.747,13.875v58.444h33.929v-92.373
		c0-4.487-2.569-5.56-5.747-2.382L92.723,274.945z"
            />
            <path
              fill="#6164ff"
              d="M241.752,219.573c-3.17,3.178-5.747,9.389-5.747,13.884v113.816h33.929V199.487
		c0-4.487-2.569-5.552-5.747-2.374L241.752,219.573z"
            />
            <path
              fill="#6164ff"
              d="M291.418,169.834c-3.178,3.17-5.755,9.38-5.755,13.867v163.563h31.547V152.212
		c0-4.487-2.577-5.56-5.755-2.382L291.418,169.834z"
            />
            <path
              fill="#6164ff"
              d="M193.078,268.239c0,0-1.512,1.52-3.381,3.39c-1.861,1.87-3.373,7.031-3.373,11.518v64.118h33.929
		v-98.047c0-4.487-2.577-5.56-5.755-2.382L193.078,268.239z"
            />
            <path
              fill="#6164ff"
              d="M142.405,250.998c-3.178-3.17-5.755-2.105-5.755,2.382v93.885h33.929v-60.03
		c0-4.487-2.439-10.559-5.454-13.558l-5.454-5.43L142.405,250.998z"
            />
            <path
              fill="#6164ff"
              d="M50.023,317.669l-10.957,10.974c-3.17,3.178-5.739,8.633-5.739,12.193v6.438h37.871V304.59
		c0-4.487-2.569-5.552-5.747-2.374L50.023,317.669z"
            />
            <path
              fill="#6164ff"
              d="M358.121,150.724c3.17,3.178,5.747,2.105,5.747-2.382V32.193c0-8.316-7.966-15.599-16.233-15.599
		H232.16c-4.487,0-5.56,2.577-2.382,5.755l41.074,41.106l-16.753,16.68l-77.701,77.774L135.3,116.82
		c-3.178-3.178-8.316-3.17-11.494,0L9.519,231.189C-3.178,243.894-3.17,264.484,9.527,277.18l0.797,0.805
		c12.697,12.697,33.287,12.697,45.975-0.008l73.247-73.287l41.098,41.057c3.178,3.17,8.324,3.17,11.502,0l135.479-135.503
		L358.121,150.724z"
            />
          </g>
        </svg>
        {children}
        <button className="sign__btn mt-2" style={{ maxWidth: '120px' }} type="button" onClick={() => setOpen(false)}>
          OK
        </button>
      </div>
    </Dialog>
  );
};

export { StakingProgressModal };
