import { useEffect, useRef, useState } from 'react';

const useUnisat = () => {
  const [unisatInstalled, setUnisatInstalled] = useState(false);
  const [connected, setConnected] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [publicKey, setPublicKey] = useState('');
  const [address, setAddress] = useState('');
  const [inscriptions, setInscriptions] = useState([]);
  const [balance, setBalance] = useState({
    confirmed: 0,
    unconfirmed: 0,
    total: 0,
  });

  const [network, setNetwork] = useState('livenet');

  const getBasicInfo = async () => {
    const unisat = window.unisat;
    const [address] = await unisat.getAccounts();
    setAddress(address);

    const publicKey = await unisat.getPublicKey();
    setPublicKey(publicKey);

    const balance = await unisat.getBalance();
    setBalance(balance);

    const network = await unisat.getNetwork();
    setNetwork(network);

    const inscriptions = await unisat.getInscriptions(0, 9999);
    setInscriptions(inscriptions);

    return { publicKey: publicKey, address: address, balance: balance, network: network, inscriptions: inscriptions };
  };

  const selfRef = useRef({ accounts: [] });

  const self = selfRef.current;
  const handleAccountsChanged = (_accounts) => {
    if (self.accounts[0] === _accounts[0]) {
      // prevent from triggering twice
      return;
    }
    self.accounts = _accounts;
    if (_accounts.length > 0) {
      setAccounts(_accounts);
      setConnected(true);

      setAddress(_accounts[0]);

      getBasicInfo();
    } else {
      setConnected(false);
    }
  };

  const handleNetworkChanged = (network) => {
    setNetwork(network);
    getBasicInfo();
  };

  useEffect(() => {
    async function checkUnisat() {
      let unisat = window.unisat;

      for (let i = 1; i < 10 && !unisat; i += 1) {
        await new Promise((resolve) => setTimeout(resolve, 100 * i));
        unisat = window.unisat;
      }

      if (unisat) {
        setUnisatInstalled(true);
      } else if (!unisat) {
        return;
      }

      unisat.getAccounts().then((accounts) => {
        handleAccountsChanged(accounts);
      });

      unisat.on('accountsChanged', handleAccountsChanged);
      unisat.on('networkChanged', handleNetworkChanged);

      return () => {
        unisat.removeListener('accountsChanged', handleAccountsChanged);
        unisat.removeListener('networkChanged', handleNetworkChanged);
      };
    }

    checkUnisat().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    network,
    connected,
    inscriptions,
    unisatInstalled,
    accounts,
    publicKey,
    address,
    balance,
    handleAccountsChanged,
    setConnected,
  };
};
export { useUnisat };
