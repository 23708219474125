import { produce } from 'immer';
import { useInitializeFilters } from './hooks/useInitializeFilters';

const Sidebar = ({ result, filters, setFilters, searchingPhrase, setSearchingPhrase, setFiltersApplied }) => {
  useInitializeFilters(result, setFilters, filters);

  const applyFilters = () => {
    setFiltersApplied(true);
  };

  const handleInputChange = (e) => {
    setSearchingPhrase(() => e.target.value);
    setFiltersApplied(() => false);
  };

  function resetFilters(filters) {
    const filterValues = Object.values(filters);
    for (const filterValue of filterValues) {
      for (const key in filterValue) {
        if (Object.hasOwnProperty.call(filterValue, key)) {
          filterValue[key] = false;
        }
      }
    }
    return filters;
  }

  const clearFilters = () => {
    setFiltersApplied(false);
    setSearchingPhrase(() => '');
    const areAllKeysFalse = Object.keys(filters).every((key) =>
      Object.values(filters[key]).every((innerValue) => innerValue === false)
    );
    if (areAllKeysFalse) return;
    const filtersCopy = JSON.parse(JSON.stringify(filters));
    const resFilters = resetFilters(filtersCopy);
    setFilters(resFilters);
  };

  const handleChecked = (category, field) => {
    setFiltersApplied(() => false);
    setFilters(
      produce((draft) => {
        draft[category][field] = !draft[category][field];
      })
    );
  };

  return (
    <>
      {/* sidebar */}
      <div className="col-12 col-xl-3 order-xl-2">
        <div className="filter-wrap">
          <button
            className="filter-wrap__btn"
            type="button"
            data-toggle="collapse"
            data-target="#collapseFilter"
            aria-expanded="false"
            aria-controls="collapseFilter"
          >
            Open filter
          </button>
          <div className="collapse filter-wrap__content" id="collapseFilter">
            {/* filter */}
            <div className="filter filter--sticky">
              <h4 className="filter__title">
                Filters{' '}
                <button type="button" onClick={clearFilters}>
                  Clear all
                </button>
              </h4>
              <div className="filter__group">
                <label className="filter__label">Keyword:</label>
                <input
                  type="text"
                  className="filter__input"
                  placeholder="Keyword"
                  onChange={handleInputChange}
                  value={searchingPhrase}
                />
              </div>

              {/* <div className="filter__group">
                <label htmlFor="sort" className="filter__label">
                  Sort by:
                </label>

                <div className="filter__select-wrap">
                  <select name="sort" id="sort" className="filter__select">
                    <option value="0">Relevance</option>
                    <option value="1">Newest</option>
                    <option value="2">Oldest</option>
                  </select>
                </div>
              </div> */}
              {Object.keys(filters).map((category) => (
                <div className="filter__group" key={category}>
                  <p style={{ color: 'white' }}>{category}</p>
                  <ul className="filter__checkboxes">
                    {Object.entries(filters[category]).map(
                      ([field, checked]) =>
                        field && (
                          <li key={field}>
                            <input
                              id={field}
                              type="checkbox"
                              name="type5"
                              checked={checked}
                              onChange={() => handleChecked(category, field)}
                            />
                            <label htmlFor={field}>{field}</label>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              ))}
              <div className="filter__group">
                <button className="filter__btn" type="button" onClick={applyFilters}>
                  APPLY FILTER
                </button>
              </div>
            </div>
            {/* end filter */}
          </div>
        </div>
      </div>
      {/* end sidebar */}
    </>
  );
};
export { Sidebar };
