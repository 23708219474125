import { useParams } from 'react-router-dom';
import { UnofficialItemsGrouped } from './unofficial-items-grouped';
import { UnofficialItemsUngrouped } from './unofficial-items-ungrouped';

const UnofficialItems = () => {
  const { groupingType } = useParams();

  return (
    <>
      {groupingType === 'grouped' && <UnofficialItemsGrouped />}
      {groupingType === 'ungrouped' && <UnofficialItemsUngrouped />}
    </>
  );
};

export { UnofficialItems };
