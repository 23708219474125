import { Fallback } from './fallback';
import icon from '../../../assets/images/icon/dark_city_icon_small.png';
import { useInventoryItemsQuery } from '../hooks/useInventoryItemsQuery';
import { useStore } from '../../../store';

const MyListings = ({ usersData }) => {
  const {
    auth: { status },
  } = useStore();
  const walletAddress = usersData?.walletAddress;
  const darkCityInventoryResults = useInventoryItemsQuery(walletAddress);
  let { isError, isLoading } = darkCityInventoryResults;

  const data = darkCityInventoryResults?.data?.listedItems;

  const inventoryEmpty = data?.length === 0;
  const notConnected = status === 'not-authenticated';

  const displayFallback = () => {
    if (notConnected) return <Fallback text="Connect to your wallet to view listings..." />;
    if (isLoading) return <Fallback text="Loading data..." />;
    if (isError) return <Fallback text="Error while loading listings..." />;
    if (inventoryEmpty) return <Fallback text="No listings available..." />;
  };

  return (
    <>
      {data?.length > 0 && !notConnected ? (
        <>
          {/* <Sidebar /> */}
          <div className="col-12 col-xl-8 order-xl-1">
            <div className="row row--grid">
              {data?.map((listing) => (
                <div className="col-12 col-lg-6 col-xl-12" key={listing.id}>
                  <div className="activity">
                    <div className="activity__cover">
                      <img src={`https://darkcity.infura-ipfs.io/ipfs/${listing.meta.image}`} alt="" />
                    </div>
                    <div className="activity__content">
                      <h3 className="activity__title">
                        <div>{listing.meta.name}</div>
                      </h3>
                      <p className="activity__text">
                        {listing.meta.type}
                        <br />
                        {listing.meta.rarity}
                      </p>
                      <span className="activity__time fs-6 text-white d-flex align-items-center">
                        <img width={22} height={22} src={icon} alt="" style={{ borderRadius: '50%' }} />{' '}
                        <span className="ml-2">{listing.price} DRK</span>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        displayFallback()
      )}
    </>
  );
};

export { MyListings };
