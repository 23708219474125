import { useQuery } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const fetchData = async (url) => {
  const response = await fetch(url, {
    credentials: 'include',
  });
  const data = await response.json();
  return data;
};

const useGetUnofficialItemsUngrouped = (resourceUri) => {
  const url = `${BASE_URL}/${resourceUri}`;

  const userItemsUngrouped = useQuery({
    queryKey: ['offchainItemsUngrouped', resourceUri],
    queryFn: () => fetchData(url),
  });

  return userItemsUngrouped;
};
export { useGetUnofficialItemsUngrouped };
