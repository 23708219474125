import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AddToCartToggle, isItemInCart } from '../../components/add-to-cart-toggle';
import { BuyItemModal } from '../../components/buy-modal';
import { Header, formatBitcoinAddress } from '../../components/header';
import { NotLoggedModal } from '../../components/not-logged-modal';
import { useStore } from '../../store';
import { BS_Carousel } from './Carousel';
import { Fallback } from './fallback';
import { useGroupedItemsQuery } from './hooks/useGroupedItemsQuery';
import { useUngroupedItemsQuery } from './hooks/useUngroupedItemsQuery';
import dark_city_icon from './img/dark-city-icon.jpg';
import { Sidebar } from './sidebar';

export const initialFilters = {
  type: {},
  rarity: {},
};

const AllItems = () => {
  const [filters, setFilters] = useState(initialFilters);
  const [visible, setVisible] = useState(15);
  const [searchingPhrase, setSearchingPhrase] = useState('');

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 5);
  };

  const groupedItemsResult = useGroupedItemsQuery(filters);
  const {
    data: groupedData,
    dataUpdatedAt: groupedDataUpdateTime,
    isLoading: groupedIsLoading,
    isError: groupedIsError,
    refetch: refetchGroupedData,
  } = groupedItemsResult;

  const ungroupedItemsResult = useUngroupedItemsQuery(filters, searchingPhrase);
  const {
    data: ungroupedData,
    dataUpdatedAt: ungroupedDataUpdateTime,
    isLoading: ungroupedIsLoading,
    isError: ungroupedIsError,
    refetch: refetchUngroupedData,
  } = ungroupedItemsResult;

  const [filtersApplied, setFiltersApplied] = useState(false);

  const lastResults = groupedDataUpdateTime > ungroupedDataUpdateTime ? groupedItemsResult : ungroupedItemsResult;

  const getFinalData = () => {
    const searchingWithPhrase = searchingPhrase && filtersApplied;
    const searchingWithoutPhrase = !searchingPhrase && filtersApplied;

    if (searchingWithPhrase) return ungroupedData;
    if (searchingWithoutPhrase) return groupedData;
    return lastResults.data;
  };

  const finalData = getFinalData();
  const finalResults = searchingPhrase && filtersApplied ? ungroupedItemsResult : groupedItemsResult;

  useEffect(() => {
    if (!searchingPhrase && filtersApplied) refetchGroupedData();
    if (searchingPhrase && filtersApplied) refetchUngroupedData();
  }, [filtersApplied, searchingPhrase, refetchGroupedData, refetchUngroupedData]);

  const [itemToBuy, setItemToBuy] = useState(null);
  const [open, setOpen] = useState(false);

  const {
    auth: { status },
    cart,
  } = useStore();

  const handleClickOpen = (e, item) => {
    setItemToBuy(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="home-8">
      {status === 'authenticated' ? (
        <BuyItemModal open={open} handleClose={handleClose} itemToBuy={itemToBuy} />
      ) : (
        <NotLoggedModal open={open} handleClose={handleClose} />
      )}
      <Header />
      <main className="main" style={{ minHeight: '70dvh' }}>
        <div className="container">
          <div style={{ marginTop: '120px' }}>
            <BS_Carousel />
          </div>
          <div className="row row--grid">
            <div className="col-12">
              <div className="main__title main__title--page">
                <h1>Explore</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <Sidebar
              filters={filters}
              setFilters={setFilters}
              result={finalResults}
              searchingPhrase={searchingPhrase}
              setSearchingPhrase={setSearchingPhrase}
              setFiltersApplied={setFiltersApplied}
            />
            <div className="col-12 col-xl-9 order-xl-1">
              <div className="row row--grid">
                {(groupedIsLoading || ungroupedIsLoading) && <Fallback text="Loading data..." />}
                {(groupedIsError || ungroupedIsError) && <Fallback text="Error while fetching data..." />}
                {finalData?.length === 0 && <Fallback text="No items match the given criteria..." />}

                {finalData &&
                  finalData.slice(0, visible).map((item, index) => (
                    <div key={index} className="col-12 col-sm-6 col-lg-4">
                      <div
                        className="card pb-3"
                        style={{ outline: isItemInCart(cart, item) ? '2px solid #6164ff' : '1px solid #222227' }}
                      >
                        <Link
                          to={item?.id ? `/explore/${item.name}/${item.id}` : `${item.name}`}
                          className="card__cover"
                        >
                          <img src={`https://darkcity.infura-ipfs.io/ipfs/${item.image}`} alt="" />
                        </Link>
                        <div className="d-flex justify-content-between align-items-center w-100 mt-2">
                          <h3 className="card__title m-0">
                            <Link to={item?.id ? `/explore/${item.name}/${item.id}` : `${item.name}`}>{item.name}</Link>
                          </h3>
                          {item?.price && (
                            <div className="card__price">
                              <img src={dark_city_icon} alt="" className="rounded-circle" height="24px" width="24px" />
                              <span className="mt-0 pl-2">{item?.price}</span>
                            </div>
                          )}
                        </div>
                        <p className="m-0 mt-2" style={{ color: '#bdbdbd' }}>
                          {item.type}
                        </p>
                        <p className="m-0 mt-0" style={{ color: '#bdbdbd', fontSize: '14px' }}>
                          {item.rarity}
                        </p>

                        {item?.count ? (
                          <p className="m-0 mt-3 text-white" style={{ fontSize: '13px' }}>
                            {item?.count} available
                          </p>
                        ) : (
                          <p className="m-0 mt-3 text-white" style={{ fontSize: '13px' }}>
                            Listed by{' '}
                            {item?.listedBy ? (
                              <Link
                                to={`/users/${item?.listedBy}`}
                                className="card__title d-inline"
                                style={{ fontSize: '13px' }}
                              >
                                {formatBitcoinAddress(item?.listedBy)}
                              </Link>
                            ) : (
                              <Link to="/explore" className="card__title d-inline" style={{ fontSize: '13px' }}>
                                Moon Runners
                              </Link>
                            )}
                          </p>
                        )}

                        <div className="card__info align-items-center pt-3">
                          {item?.id && <AddToCartToggle item={item} setOpen={setOpen} />}
                          <div className="filter__group w-auto ml-auto">
                            {item?.id ? (
                              <button
                                className="filter__btn py-2 px-3 h-auto w-auto text-capitalize"
                                type="button"
                                onClick={(e) => handleClickOpen(e, item)}
                              >
                                Buy now
                              </button>
                            ) : (
                              <>
                                <Link to={`${item.name}`}>
                                  <button className="filter__btn py-2 px-3 h-auto w-auto text-capitalize" type="button">
                                    See more
                                  </button>
                                </Link>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {finalData && visible < finalData.length && (
                <div className="row row--grid">
                  <div className="col-12">
                    <button className="main__load" onClick={showMoreItems}>
                      Load more
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export { AllItems };
