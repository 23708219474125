import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { Zoom } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const WarningModal = ({ open, setOpen, children }) => {
  return (
    <Dialog
      data-testid="transaction-success-dialog"
      open={open}
      TransitionComponent={Transition}
      slotProps={{
        backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
      }}
      PaperProps={{ style: { backgroundColor: '#16151a', padding: '40px 80px 25px 80px', maxWidth: '520px' } }}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <button
        className="modal__close"
        type="button"
        style={{ top: '37px', right: '37px' }}
        onClick={() => setOpen(false)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
        </svg>
      </button>
      <div className="d-flex align-items-center justify-content-center flex-column gap-3 px-4">
        <svg width="120px" height="120px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="17" r="1" fill="orange" />
          <path d="M12 10L12 14" stroke="orange" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M3.44722 18.1056L10.2111 4.57771C10.9482 3.10361 13.0518 3.10362 13.7889 4.57771L20.5528 18.1056C21.2177 19.4354 20.2507 21 18.7639 21H5.23607C3.7493 21 2.78231 19.4354 3.44722 18.1056Z"
            stroke="orange"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {children}
      </div>
    </Dialog>
  );
};

export { WarningModal };
