import { useMutation, useQueryClient } from '@tanstack/react-query';
import { signMessage } from '../../../auth/unisat';
import { BASE_URL } from '../../../../config';
import { v4 as uuidv4 } from 'uuid';

export const generateRandomMessage = () => {
  const randomMessage = uuidv4();
  return randomMessage;
};

const useClaimRewardsUnisat = () => {
  const queryClient = useQueryClient();

  const claimRewardsUnisatMutation = useMutation({
    mutationKey: ['unstakeUnisat'],

    mutationFn: async ({ publicKey, address }) => {
      const randomMessage = generateRandomMessage();

      const signature = await signMessage(randomMessage);

      const body = {
        publicKey: publicKey,
        message: randomMessage,
        signature: signature,
        address: address,
      };

      const response = await fetch(`${BASE_URL}/unstake`, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to execute unstake operation');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stakeData', 'claimedReward']);
    },
  });

  return claimRewardsUnisatMutation;
};

export { useClaimRewardsUnisat };
