import { Zoom } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { useListItem } from '../../pages/your-inventory/unofficial-items/useListItem';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const ListingModal = ({ setOpen, open, itemId }) => {
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState('');

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const listMutation = useListItem(itemId);

  const listItem = () => {
    if (!value || value === '0') {
      setError('Please select amount');
      return;
    }

    setError('');

    listMutation.mutate(String(value), {
      onSuccess: async () => {
        //todo: success modal
        console.log('listing successfull');
      },
    });

    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
        }}
        PaperProps={{
          style: { backgroundColor: '#16151a', padding: '80px 80px 25px 80px', width: '500px' },
        }}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <>
          <button
            className="modal__close"
            type="button"
            style={{ top: '37px', right: '37px' }}
            onClick={() => setOpen(false)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
            </svg>
          </button>
          <div className="filter__group align-items-center">
            <label className="filter__label">Select price in DRK</label>
            <input type="number" className="filter__input" value={value} onChange={handleInputChange} />
          </div>
          {error && <div className="text-white text-center">{error}</div>}
          <button className="sign__btn mt-4" style={{ maxWidth: '120px' }} type="button" onClick={listItem}>
            List
          </button>
        </>
      </Dialog>
    </>
  );
};

export { ListingModal };
