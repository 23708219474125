import { Navigate } from 'react-router-dom';
import { Activity } from '../pages/activity';
import { ActivityItem } from '../pages/activity-item';
import { AllItems } from '../pages/all-items';
import Items_itemDetails from '../pages/all-items-item-details';
import { UngroupedItems } from '../pages/all-items/ungroupedItems';
import { YourInventory } from '../pages/your-inventory';
import MintedItemDetails from '../pages/your-inventory/official-items/item-details';
import UnmintedItemDetails from '../pages/your-inventory/unofficial-items/item-details';
import { UserProfile } from '../pages/user-profile';
import { RedirectToOffchainItems } from '../pages/user-profile/Redirect';
import UserProfileUnmintedItemDetails from '../pages/user-profile/unofficial-items/item-details';
import UserProfileMintedItemDetails from '../pages/user-profile/official-items/item-details';
import React from 'react';
import { CustomUserProfile } from '../pages/custom-profile';
import CustomUserProfileMintedItemDetails from '../pages/custom-profile/official-items/item-details';
import CustomUserProfileUnmintedItemDetails from '../pages/custom-profile/unofficial-items/item-details';
import { RedirectToCustomOffchainItems } from '../pages/custom-profile/Redirect';
import TransferDetails from '../pages/transfer-details';
import { Stake } from '../pages/stake';

const routes = [
  { isIndex: true, component: <Navigate to="/explore" replace /> },
  { path: '/explore', exact: true, component: <AllItems /> },

  // Inventory
  { path: '/your-inventory/:tab/:groupingType?/:page?', component: <YourInventory /> },
  { path: '/your-inventory/:tab/:groupingType/:itemName/:page?', component: <YourInventory /> },

  // item details
  { path: '/your-inventory/minted-items/:itemId', component: <MintedItemDetails /> },
  { path: '/your-inventory/not-minted/:itemId', component: <UnmintedItemDetails /> },

  { path: '/explore/:name', component: <UngroupedItems /> },
  { path: '/explore/:name/:itemId', component: <Items_itemDetails /> },
  { path: '/activity', component: <Activity /> },
  { path: '/activity/:id', component: <ActivityItem /> },

  // order details
  { path: '/orders/:orderId', component: <TransferDetails /> },

  // user profile based on walletId
  { path: '/users/:walletId', component: <RedirectToOffchainItems /> },
  { path: '/users/:walletId/:tab/:groupingType?/:page?', component: <UserProfile /> },
  { path: '/users/:walletId/:tab/:groupingType?/:itemName/:page?', component: <UserProfile /> },

  // user profile based on walletId details
  { path: '/users/:walletId/minted-items/:itemId?', component: <UserProfileMintedItemDetails /> },
  { path: '/users/:walletId/off-chain/items/:itemId?', component: <UserProfileUnmintedItemDetails /> },

  // custom user profile - based on postfix, which can be set up in profile settings
  { path: '/shop/:postfix', component: <RedirectToCustomOffchainItems /> },

  // custom user profile - not-minted items with grouping
  { path: '/shop/:postfix/:tab/:groupingType?/:page?', component: <CustomUserProfile /> },
  { path: '/shop/:postfix/:tab/:groupingType?/:itemName/:page?', component: <CustomUserProfile /> },

  // custom user profile - item details
  { path: '/shop/:postfix/minted-items/:itemId?', component: <CustomUserProfileMintedItemDetails /> },
  { path: '/shop/:postfix/off-chain/:itemId?', component: <CustomUserProfileUnmintedItemDetails /> },

  // staking
  { path: '/staking', component: <Stake /> },
];

export default routes;
