import { Link, useNavigate } from 'react-router-dom';
import { Header } from '../../components/header';

const Fallback = ({ text }) => {
  const navigate = useNavigate();

  return (
    <>
      <>
        <Header />
        <main className="main" style={{ minHeight: '100dvh' }}>
          <div className="container">
            <div className="row row--grid">
              {/* breadcrumb */}
              <div className="col-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb__item">
                    <Link onClick={() => navigate(-2)}>Home</Link>
                  </li>
                  <li className="breadcrumb__item">
                    <Link onClick={() => navigate(-1)}>type</Link>
                  </li>
                  <li className="breadcrumb__item breadcrumb__item--active">id</li>
                </ul>
              </div>
              <div className="col-12">
                <div className="main__title main__title--page">
                  <h1>{text}</h1>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    </>
  );
};

export { Fallback };
