import { useQuery } from '@tanstack/react-query';
import { BASE_URL } from '../../../../config';

const useProfileDataQuery = (userId) => {
  const userProfileResults = useQuery({
    queryKey: ['profile', userId],
    queryFn: async () => {
      const response = await fetch(`${BASE_URL}/users/profiles/${userId}`);

      return response.json();
    },
  });

  return userProfileResults;
};

export { useProfileDataQuery };
