import { useMutation, useQueryClient } from '@tanstack/react-query';
import { signMessage } from '../../../auth/unisat';
import { BASE_URL } from '../../../../config';
import { v4 as uuidv4 } from 'uuid';

export const generateRandomMessage = () => {
  const randomMessage = uuidv4();
  return randomMessage;
};

const useStakeUnisat = () => {
  const queryClient = useQueryClient();

  const stakeMutation = useMutation({
    mutationKey: ['stake'],

    mutationFn: async ({ publicKey, address, amount }) => {
      const randomMessage = generateRandomMessage();

      const signature = await signMessage(randomMessage);

      const body = {
        publicKey: publicKey,
        message: randomMessage,
        signature: signature,
        address: address,
        amount: amount,
      };

      const response = await fetch(`${BASE_URL}/stake`, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to execute stake operation');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stakeData']);
    },
  });

  return stakeMutation;
};

export { useStakeUnisat };
