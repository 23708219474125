import { Zoom } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { useStore } from '../../store';
import { InscriptionList } from './inscriptionList';
import { NotAuthenticated } from './notAuthenticated';
import { XverseInstructions } from './xverseInstructions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDuration: '800ms' }} />;
});

const TransferModal = ({ setOpen, open, data, isLoading }) => {
  const {
    auth: { status, walletType },
  } = useStore();

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(34, 34, 39, 0.75)' } },
        }}
        PaperProps={{
          style: {
            backgroundColor: '#16151a',

            // TODO: fix - extract to different modal, to avoid doing poor if
            padding: walletType === 'xverse' ? '40px 20px 25px 20px' : '40px 70px 25px 70px',
            width: '600px',
          },
        }}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        {status === 'not-authenticated' && <NotAuthenticated setOpen={setOpen} />}
        {isLoading && !data && <p className="text-white text-center">Loading...</p>}
        {data && walletType === 'unisat' && <InscriptionList data={data} setOpen={setOpen} />}
        {data && walletType === 'xverse' && <XverseInstructions data={data} setOpen={setOpen} />}
      </Dialog>
    </>
  );
};

export { TransferModal };
