import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useLastResults } from '../../../all-items/hooks/useLastResults';
import { applySearching } from '../../utils';
import { Fallback } from '../fallback';
import { useGetUnofficialItemsUngrouped } from '../useGetUnofficialItemsUngrouped';
import { GoBackLink } from '../../../../components/go-back-link';

const itemsPerPage = 6;

const UnofficialItemsUngrouped = ({ searchingPhrase, profileUri }) => {
  const { page, itemName } = useParams();
  const {
    state: { resourceUri },
  } = useLocation();

  const [itemsRange, setItemsRange] = useState([0, 6]);

  // todo: pagination from backend
  useEffect(() => {
    const pageNumber = parseInt(page, 10) || 1;

    const start = (pageNumber - 1) * itemsPerPage;
    const end = (pageNumber - 1) * itemsPerPage + itemsPerPage;
    const initialItemsRange = [start, end];

    setItemsRange(initialItemsRange);
  }, [page]);

  const darkCityInventoryResults = useGetUnofficialItemsUngrouped(resourceUri);
  let { data, error, isLoading, isPending } = darkCityInventoryResults;

  const lastResults = useLastResults(darkCityInventoryResults);

  const shouldDisplayLastResults = lastResults && (isLoading || isPending);
  const finalData = shouldDisplayLastResults ? lastResults : data;

  const items = applySearching(finalData, searchingPhrase);
  const pages = Math.ceil(items?.length / itemsPerPage);

  const loading = (isLoading || isPending) && !lastResults && data === undefined;
  const isError = error;
  const inventoryEmpty = data?.length === 0;

  const displayFallback = () => {
    if (loading) return <Fallback text="Loading data..." />;
    if (isError) return <Fallback text="Error while loading items..." />;
    if (inventoryEmpty) return <Fallback text="Inventory is empty..." />;
  };

  return (
    <>
      <div className="row row--grid">
        <div className="col-12">
          <GoBackLink to={`/shop/${profileUri}/off-chain/grouped/1`} />
        </div>
        {items?.length > 0
          ? items.slice(itemsRange[0], itemsRange[1]).map((item, index) => (
              <div className="col-12 col-sm-6 col-lg-4" key={index}>
                <div className="card pb-3">
                  <Link to={`/shop/${profileUri}/off-chain/${item.id}`} state={{ item: item }} className="card__cover">
                    <img src={`https://darkcity.infura-ipfs.io/ipfs/${item.meta.image}`} alt="" />
                  </Link>
                  <div className="d-flex align-items-center justify-between w-100 mt-2">
                    <h3 className="card__title m-0">
                      <Link to={`/shop/${profileUri}/off-chain/${item.id}`} state={{ item: item }}>
                        {item.meta.name}
                      </Link>
                    </h3>
                  </div>
                  <p className="m-0 mt-3" style={{ color: '#bdbdbd' }}>
                    {item.meta.type}
                  </p>
                  <div
                    className="m-0 mt-0 d-flex justify-content-between w-100"
                    style={{ color: '#bdbdbd', fontSize: '14px' }}
                  >
                    {item.meta.rarity}
                    {item?.listed === 1 && (
                      <p className="m-0 p-0">
                        Listed for <b className="text-white">{item?.price} DRK</b>{' '}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))
          : displayFallback()}
      </div>
      {/* paginator */}
      {items?.length > 0 && (
        <div className="row row--grid">
          <div className="col-12">
            <div className="paginator">
              <span className="paginator__pages">
                {itemsRange[1] > items.length
                  ? `${items.length} from ${items?.length} total items`
                  : `${itemsRange[0]}-${itemsRange[1]} from ${items.length} total items`}
              </span>
              {pages > 1 && (
                <ul className="paginator__list">
                  <li>
                    <Link
                      to={`/shop/${profileUri}/off-chain/ungrouped/${itemName}/${Number(page) - 1}`}
                      state={{ resourceUri: resourceUri }}
                      style={Number(page) === 1 ? { pointerEvents: 'none' } : {}}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z" />
                      </svg>
                    </Link>
                  </li>
                  {Array.from({ length: pages }).map((_, index) => (
                    <li key={index} className={index + 1 === Number(page) ? 'active' : ''}>
                      <Link
                        to={`/shop/${profileUri}/off-chain/ungrouped/${itemName}/${index + 1}`}
                        state={{ resourceUri: resourceUri }}
                      >
                        {index + 1}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link
                      to={`/shop/${profileUri}/off-chain/ungrouped/${itemName}/${page + 1}`}
                      style={Number(page) === pages ? { pointerEvents: 'none' } : {}}
                      state={{ resourceUri: resourceUri }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
                      </svg>
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { UnofficialItemsUngrouped };
