import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const useMintingConfirmedXverse = () => {
  const queryClient = useQueryClient();

  const mintItemConfirmedXverse = useMutation({
    mutationKey: ['mintItemConfirmedXverse'],
    mutationFn: async ({ id, txId }) => {
      const body = { txId: txId };

      const response = await fetch(`${BASE_URL}/items/${id}/mint/xverse`, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Failed to send tx confirmation from xverse wallet');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['offchainItems']);
    },
  });

  return mintItemConfirmedXverse;
};

export { useMintingConfirmedXverse };
