import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { createInscription } from 'sats-connect';
import { DelistingItemModal } from '../../../../components/delisting-item-modal';
import { ErrorModal } from '../../../../components/error-modal';
import { GiftItemModal } from '../../../../components/gift-item-modal';
import { GoBackLink } from '../../../../components/go-back-link';
import { ListingModal } from '../../../../components/listing-modal';
import { SuccessModalUnisat } from '../../../../components/success-modal-unisat';
import { useStore } from '../../../../store';
import { useLastResults } from '../../../all-items/hooks/useLastResults';
import { applySearching } from '../../utils';
import { Fallback } from '../fallback';
import { useGetUnofficialItemsUngrouped } from '../useGetUnofficialItemsUngrouped';
import { useMintItem } from '../useMintItem';
import { useMintItemXverse } from '../useMintItemXverse';
import { useMintingConfirmedUnisat } from '../useMintingConfirmedUnisat';
import { useMintingConfirmedXverse } from '../useMintingConfirmedXverse';

const itemsPerPage = 6;

const UnofficialItemsUngrouped = ({ searchingPhrase }) => {
  const { page, itemName } = useParams();
  const {
    state: { resourceUri },
  } = useLocation();

  const [itemsRange, setItemsRange] = useState([0, 6]);
  const {
    auth: { status, walletType },
  } = useStore();

  // todo: pagination from backend
  useEffect(() => {
    const pageNumber = parseInt(page, 10) || 1;

    const start = (pageNumber - 1) * itemsPerPage;
    const end = (pageNumber - 1) * itemsPerPage + itemsPerPage;
    const initialItemsRange = [start, end];

    setItemsRange(initialItemsRange);
  }, [page]);

  const darkCityInventoryResults = useGetUnofficialItemsUngrouped(resourceUri);
  let { data, error, isLoading, isPending } = darkCityInventoryResults;

  const lastResults = useLastResults(darkCityInventoryResults);

  const shouldDisplayLastResults = lastResults && (isLoading || isPending);
  const finalData = shouldDisplayLastResults ? lastResults : data;

  const items = applySearching(finalData, searchingPhrase);
  const pages = Math.ceil(items?.length / itemsPerPage);

  const loading = (isLoading || isPending) && !lastResults && data === undefined;
  const isError = error;
  const inventoryEmpty = data?.length === 0;
  const notConnected = status === 'not-authenticated';

  const displayFallback = () => {
    if (notConnected) return <Fallback text="Connect to your wallet to view inventory..." />;
    if (loading) return <Fallback text="Loading data..." />;
    if (isError) return <Fallback text="Error while loading items..." />;
    if (inventoryEmpty) return <Fallback text="Your inventory is empty..." />;
  };

  const mintItemUnisat = useMintItem();
  const mintItemXverse = useMintItemXverse();
  const mintConfirmedXverse = useMintingConfirmedXverse();
  const mintConfirmedUnisat = useMintingConfirmedUnisat();

  const [open, setOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const [listingModalOpen, setListingModalOpen] = useState(false);
  const [delistingModalOpen, setDelistingModalOpen] = useState(false);
  const [idItemToList, setIdItemToList] = useState(null);

  const [transactionId, setTransactionId] = useState('');
  const [orderId, setOrderId] = useState('');

  const mintItem = (e, id) => {
    if (walletType === 'unisat') {
      handleMintingItemUnisat(id);
    } else if (walletType === 'xverse') {
      handleMintingItemXverse(id);
    }
  };

  const openListingModal = (e, id) => {
    setIdItemToList(() => id);
    setListingModalOpen(() => true);
  };

  const openDelistingModal = (e, id) => {
    setIdItemToList(() => id);
    setDelistingModalOpen(() => true);
  };

  function handleMintingItemUnisat(id) {
    // first we get information needed for transaction
    mintItemUnisat.mutate(String(id), {
      onSuccess: async (data) => {
        try {
          let txid = await window.unisat.sendBitcoin(data?.walletAddress, data?.paymentAmount, {
            feeRate: data?.feeRate,
          });
          if (txid) {
            setTransactionId(txid);

            // then we register, when user confirmed transaction in our database
            mintConfirmedUnisat.mutate(
              { id: id, orderId: data?.orderId, txId: txid },
              {
                onSuccess: () => {
                  setOpen(true);
                },

                onError: () => {
                  setOrderId(data?.orderId);
                  setErrorModalOpen(true);
                },
              }
            );
          }
        } catch (e) {
          console.log(e);
        }
      },
    });
  }

  function handleMintingItemXverse(id) {
    mintItemXverse.mutate(String(id), {
      onSuccess: async (data) => {
        await createInscription({
          payload: {
            network: {
              type: 'Mainnet',
            },
            content: data?.content,
            contentType: data?.contentType,
            payloadType: data?.payloadType,
            suggestedMinerFeeRate: data?.feeRate,
          },
          onFinish: (response) => {
            setTransactionId(response.txId);

            mintConfirmedXverse.mutate(
              { id: id, txId: response.txId },
              {
                onSuccess: () => {
                  setOpen(true);
                },

                onError: () => {
                  // todo - check, if orderId is implemented for xverse too
                  setOrderId(data?.orderId);
                  setErrorModalOpen(true);
                },
              }
            );
          },
          onCancel: () => alert('Canceled'),
        });
      },
    });
  }

  const [idItemToGift, setIdItemToGift] = useState(null);

  const handleOpenGiftModal = (e, itemId) => {
    setIdItemToGift(() => itemId);
    setGiftItemModalOpen(() => true);
  };

  const [giftItemModalOpen, setGiftItemModalOpen] = useState(false);

  const shouldDisableButtonFor = (item) => {
    return (
      walletType === 'xverse' ||
      item?.meta?.type === 'maintenance' ||
      item?.listed === 1 ||
      item?.meta?.name === 'Burner Scan'
    );
  };

  return (
    <>
      <ListingModal open={listingModalOpen} setOpen={setListingModalOpen} itemId={idItemToList} />
      <GiftItemModal open={giftItemModalOpen} setOpen={setGiftItemModalOpen} itemId={idItemToGift} />
      <DelistingItemModal open={delistingModalOpen} setOpen={setDelistingModalOpen} itemId={idItemToList} />
      <SuccessModalUnisat open={open} setOpen={setOpen} transactionId={transactionId} />
      <ErrorModal open={errorModalOpen} setOpen={setErrorModalOpen}>
        <h4 className="sign__title mb-1" style={{ color: 'white' }}>
          Something went wrong
        </h4>
        <p className="modal__text fs-6 mb-0">Copy this data and contact us:</p>
        <ul>
          <li className="m-0 modal__text mb-0 fs-6">Transaction ID - {transactionId}</li>
          <li className="m-0 modal__text mb-0 fs-6">Order ID - {orderId}</li>
        </ul>
      </ErrorModal>
      <div className="row row--grid">
        {!notConnected && (
          <div className="col-12">
            <GoBackLink to={'/your-inventory/not-minted/grouped/1'} />
          </div>
        )}
        {items?.length > 0 && !notConnected
          ? items.slice(itemsRange[0], itemsRange[1]).map((item, index) => (
              <div className="col-12 col-sm-6 col-lg-4" key={index}>
                <div className="card pb-3">
                  <Link to={`/your-inventory/not-minted/${item.id}`} state={{ item: item }} className="card__cover">
                    <img src={`https://darkcity.infura-ipfs.io/ipfs/${item.meta.image}`} alt="" />
                  </Link>
                  <div className="d-flex align-items-center justify-between w-100 mt-2">
                    <h3 className="card__title m-0">
                      <Link to={`/your-inventory/not-minted/${item.id}`} state={{ item: item }}>
                        {item.meta.name}
                      </Link>
                    </h3>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="gift-item-tooltip">Gift item</Tooltip>}>
                      <button onClick={(e) => handleOpenGiftModal(e, item.id)} aria-label="gift item">
                        <svg
                          width="20px"
                          height="20px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 7V20M12 7H8.46429C7.94332 7 7.4437 6.78929 7.07533 6.41421C6.70695 6.03914 6.5 5.53043 6.5 5C6.5 4.46957 6.70695 3.96086 7.07533 3.58579C7.4437 3.21071 7.94332 3 8.46429 3C11.2143 3 12 7 12 7ZM12 7H15.5357C16.0567 7 16.5563 6.78929 16.9247 6.41421C17.293 6.03914 17.5 5.53043 17.5 5C17.5 4.46957 17.293 3.96086 16.9247 3.58579C16.5563 3.21071 16.0567 3 15.5357 3C12.7857 3 12 7 12 7ZM5 12H19V17.8C19 18.9201 19 19.4802 18.782 19.908C18.5903 20.2843 18.2843 20.5903 17.908 20.782C17.4802 21 16.9201 21 15.8 21H8.2C7.07989 21 6.51984 21 6.09202 20.782C5.71569 20.5903 5.40973 20.2843 5.21799 19.908C5 19.4802 5 18.9201 5 17.8V12ZM4.6 12H19.4C19.9601 12 20.2401 12 20.454 11.891C20.6422 11.7951 20.7951 11.6422 20.891 11.454C21 11.2401 21 10.9601 21 10.4V8.6C21 8.03995 21 7.75992 20.891 7.54601C20.7951 7.35785 20.6422 7.20487 20.454 7.10899C20.2401 7 19.9601 7 19.4 7H4.6C4.03995 7 3.75992 7 3.54601 7.10899C3.35785 7.20487 3.20487 7.35785 3.10899 7.54601C3 7.75992 3 8.03995 3 8.6V10.4C3 10.9601 3 11.2401 3.10899 11.454C3.20487 11.6422 3.35785 11.7951 3.54601 11.891C3.75992 12 4.03995 12 4.6 12Z"
                            stroke="#6164ff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </OverlayTrigger>
                  </div>
                  <p className="m-0 mt-3" style={{ color: '#bdbdbd' }}>
                    {item.meta.type}
                  </p>
                  <div
                    className="m-0 mt-0 d-flex justify-content-between w-100"
                    style={{ color: '#bdbdbd', fontSize: '14px' }}
                  >
                    {item.meta.rarity}
                    {item?.listed === 1 && (
                      <p className="m-0 p-0">
                        Listed for <b className="text-white">{item?.price} DRK</b>{' '}
                      </p>
                    )}
                  </div>
                  <div className="card__info align-items-center pt-3">
                    {item.status === 'notMinted' ? (
                      <div className="filter__group">
                        <div className="d-flex justify-content-between w-100">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              item?.meta?.type === 'maintenance' || item?.meta?.name === 'Burner Scan' ? (
                                <Tooltip id="transferable-tooltip">
                                  This is a maintenance item. You cannot mint it.
                                </Tooltip>
                              ) : item?.listed === 1 ? (
                                <Tooltip id="listed-tooltip">You can&apos;t mint listed items</Tooltip>
                              ) : walletType === 'xverse' ? (
                                <Tooltip id="available-soon-tooltip">Available soon</Tooltip>
                              ) : (
                                <Tooltip className="d-none"></Tooltip>
                              )
                            }
                          >
                            <div>
                              <button
                                disabled={shouldDisableButtonFor(item)}
                                className="filter__btn py-2 px-3 h-auto w-auto text-capitalize item-owned"
                                type="button"
                                data-testid="mint-button"
                                onClick={(e) => mintItem(e, item.id)}
                                style={{
                                  opacity: shouldDisableButtonFor(item) ? 0.5 : 1,
                                  pointerEvents: shouldDisableButtonFor(item) ? 'none' : 'all',
                                }}
                              >
                                Mint
                              </button>
                            </div>
                          </OverlayTrigger>
                          {item?.listed === 1 ? (
                            <div>
                              <button
                                className="filter__btn py-2 px-4 h-auto w-auto text-capitalize item-owned"
                                type="button"
                                onClick={(e) => openDelistingModal(e, item.id)}
                              >
                                Delist
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                className="filter__btn py-2 px-4 h-auto w-auto text-capitalize item-owned"
                                type="button"
                                onClick={(e) => openListingModal(e, item.id)}
                              >
                                List
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="filter__btn py-2 px-3 h-auto w-auto item-owned pe-none text-capitalize gap-2">
                          <div
                            className="spinner-border spinner-border-sm text-6164ff spinner-slower"
                            role="status"
                            style={{ marginTop: '1px' }}
                          ></div>
                          <span>Minting...</span>
                        </div>
                        <a
                          href={`https://mempool.space/address/${item.txId}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ fontSize: '14px' }}
                        >
                          Mempool link
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))
          : displayFallback()}
      </div>
      {/* paginator */}
      {items?.length > 0 && !notConnected && (
        <div className="row row--grid">
          <div className="col-12">
            <div className="paginator">
              <span className="paginator__pages">
                {itemsRange[1] > items.length
                  ? `${items.length} from ${items?.length} total items`
                  : `${itemsRange[0]}-${itemsRange[1]} from ${items.length} total items`}
              </span>
              {pages > 1 && (
                <ul className="paginator__list">
                  <li>
                    <Link
                      to={`/your-inventory/not-minted/ungrouped/${itemName}/${Number(page) - 1}`}
                      state={{ resourceUri: resourceUri }}
                      style={Number(page) === 1 ? { pointerEvents: 'none' } : {}}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z" />
                      </svg>
                    </Link>
                  </li>
                  {Array.from({ length: pages }).map((_, index) => (
                    <li key={index} className={index + 1 === Number(page) ? 'active' : ''}>
                      <Link
                        to={`/your-inventory/not-minted/ungrouped/${itemName}/${index + 1}`}
                        state={{ resourceUri: resourceUri }}
                      >
                        {index + 1}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link
                      to={`/your-inventory/not-minted/ungrouped/${itemName}/${Number(page) + 1}`}
                      style={Number(page) === pages ? { pointerEvents: 'none' } : {}}
                      state={{ resourceUri: resourceUri }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
                      </svg>
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { UnofficialItemsUngrouped };
