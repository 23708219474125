// Na razie filtrowanie i szukanie tutaj po stronie frontendu
const applySearching = (items, searchingPhrase) => {
  if (!searchingPhrase || searchingPhrase?.length === 0) return items;
  if (!items) return;

  const dataAfterSearching = items.filter((item) => {
    const keys = Object.keys(item.meta);

    return keys.some((key) => {
      const value = item.meta[key];
      if (typeof value === 'string') {
        return value.toLowerCase().includes(searchingPhrase.toLowerCase());
      }
      return false;
    });
  });

  return dataAfterSearching;
};

const gatherFilterConditions = (filters) => {
  const filterConditions = {};
  for (const category in filters) {
    for (const categoryItem in filters[category]) {
      if (filters[category][categoryItem] === true) {
        filterConditions[category] = filterConditions[category] || [];
        filterConditions[category].push(categoryItem);
      }
    }
  }
  return filterConditions;
};

const applyFiltersFor = (items, filters) => {
  const filterConditions = gatherFilterConditions(filters);

  const filtersNotApplied = Object.keys(filterConditions).length === 0;
  if (filtersNotApplied) return items;

  const filteredItems = items.filter((item) =>
    Object.keys(filterConditions).every((key) => {
      const itemDetails = item.meta || item;

      return filterConditions[key].some((condition) => itemDetails[key] && itemDetails[key] === condition);
    })
  );

  return filteredItems;
};

const buildRequestBody = (walletId, inscriptions) => {
  if (!walletId || !inscriptions) return '';
  const body = {
    accountId: walletId,
    items: [],
  };

  for (const inscription of inscriptions) {
    body.items.push({ itemURI: inscription.content, inscryptionId: inscription.inscriptionId });
  }

  return body;
};

export { buildRequestBody, applyFiltersFor, applySearching };
