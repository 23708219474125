import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const useDelistItem = (itemId) => {
  const queryClient = useQueryClient();

  const delistItemMutation = useMutation({
    mutationKey: ['delistItem'],
    mutationFn: async () => {
      const response = await fetch(`${BASE_URL}/items/${itemId}/unlist`, {
        method: 'POST',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to delist item');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['offchainItems']);
      queryClient.invalidateQueries(['inventory']);
    },
  });

  return delistItemMutation;
};

export { useDelistItem };
