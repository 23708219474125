import { useEffect, useState } from 'react';

const useLastResults = (result) => {
  const [lastResults, setLastResults] = useState(null);

  useEffect(() => {
    if (result.data) {
      setLastResults(result.data);
    }
  }, [result.data]);

  return lastResults;
};

export { useLastResults };
