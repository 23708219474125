import { useQuery } from '@tanstack/react-query';
import { BASE_URL } from '../../../../config';

export const QUIZ_NOT_DONE = 'QUIZ NOT DONE';

const useQuizData = () => {
  const quizData = useQuery({
    queryKey: ['quizData'],
    queryFn: async () => {
      const response = await fetch(`${BASE_URL}/stake/quiz`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.status === 204) {
        return QUIZ_NOT_DONE;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch quiz data');
      }

      return response.json();
    },
  });

  return quizData;
};

export { useQuizData };
