import { persist, createJSONStorage } from 'zustand/middleware';
import { create } from 'zustand';

export const AUTHENTICATED_STATUS = 'authenticated';
export const NOT_AUTHENTICATED_STATUS = 'not-authenticated';
export const SESSION_EXPIRED_STATUS = 'session-expired';

export const storeCreator = persist(
  (set) => ({
    auth: { status: 'not-authenticated', address: null, sessionExpired: false },
    setAuth: (authInfo) => set({ auth: authInfo }),
    cart: [],
    addToCart: (item) => set((state) => ({ cart: [...state.cart, item] })),
    removeFromCart: (item) => set((state) => ({ cart: state.cart.filter((_item) => _item.id !== item.id) })),
    clearCart: () => set({ cart: [] }),
  }),
  {
    name: 'dark-city',
    storage: createJSONStorage(() => sessionStorage),
  }
);

const useStore = create()(storeCreator);

export { useStore };
