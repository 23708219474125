import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLastResults } from '../../all-items/hooks/useLastResults';
import { applySearching } from '../utils';
import { Fallback } from './fallback';

const itemsPerPage = 6;

// TODO: zaadaptować do user-profile
const OfficialItems = ({ searchingPhrase, profileDataResults }) => {
  const { page } = useParams();
  const [itemsRange, setItemsRange] = useState([0, 6]);

  // todo: paginacja z backendu
  useEffect(() => {
    const pageNumber = parseInt(page, 10) || 1;

    const start = (pageNumber - 1) * itemsPerPage;
    const end = (pageNumber - 1) * itemsPerPage + itemsPerPage;
    const initialItemsRange = [start, end];

    setItemsRange(initialItemsRange);
  }, [page]);

  let { data, error, isLoading, isPending } = profileDataResults;

  const lastResults = useLastResults(profileDataResults);

  const shouldDisplayLastResults = lastResults && (isLoading || isPending);
  const finalData = shouldDisplayLastResults ? lastResults?.items : data?.items;

  const items = applySearching(finalData, searchingPhrase);
  const pages = Math.ceil(items?.length / itemsPerPage);

  const loading = (isLoading || isPending) && !lastResults && data === undefined;
  const isError = error;
  const inventoryEmpty = data?.items?.length === 0;

  const displayFallback = () => {
    if (loading) return <Fallback text="Loading data..." />;
    if (isError) return <Fallback text="Error while loading items..." />;
    if (inventoryEmpty) return <Fallback text="Inventory is empty..." />;
  };

  return (
    <>
      <div className="row row--grid">
        {items?.length > 0
          ? items.slice(itemsRange[0], itemsRange[1]).map((item, index) => (
              <div className="col-12 col-sm-6 col-lg-4" key={index}>
                <div className="card pb-3">
                  {/* TODO */}
                  <Link
                    className="card__cover"
                    to={`/shop/${data?.profileUri}/minted-items/${item.id}`}
                    state={{ item: item }}
                  >
                    <img src={`https://darkcity.infura-ipfs.io/ipfs/${item.meta.image}`} alt="" />
                  </Link>
                  <h3 className="card__title m-0 mt-2">
                    {/* TODO */}
                    <Link to={`/shop/${data?.profileUri}/not-minted-items/${item.id}`} state={{ item: item }}>
                      {item.meta.name}
                    </Link>
                  </h3>
                  <p className="m-0 mt-3" style={{ color: '#bdbdbd' }}>
                    {item.meta.type}
                  </p>
                  <p className="m-0 mt-0" style={{ color: '#bdbdbd', fontSize: '14px' }}>
                    {item.meta.rarity}
                  </p>
                  {/* <div className="card__info align-items-center pt-3">
                    <div className="card__price">
                      <img src={dark_city_icon} alt="" className="rounded-circle" height="24px" width="24px" />
                      <span className="mt-0 pl-2">300 DRK</span>
                    </div>
                    <div className="filter__group w-auto">
                      <button
                        className="filter__btn py-2 px-3 h-auto w-auto text-capitalize item-owned pe-none"
                        type="button"
                      >
                        Yours
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            ))
          : displayFallback()}
      </div>
      {/* paginator */}
      {items?.length > 0 && (
        <div className="row row--grid">
          <div className="col-12">
            <div className="paginator">
              <span className="paginator__pages">
                {itemsRange[1] > items.length
                  ? `${items.length} from ${items?.length} total items`
                  : `${itemsRange[0]}-${itemsRange[1]} from ${items.length} total items`}
              </span>
              {pages > 1 && (
                <ul className="paginator__list">
                  <li>
                    {/* TODO */}
                    <Link
                      to={`/shop/${data?.profileUri}/on-chain/${Number(page) - 1}`}
                      style={Number(page) === 1 ? { pointerEvents: 'none' } : {}}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z" />
                      </svg>
                    </Link>
                  </li>

                  {Array.from({ length: pages }).map((_, index) => (
                    <li key={index} className={index + 1 === Number(page) ? 'active' : ''}>
                      {/* TODO */}
                      <Link to={`/shop/${data?.profileUri}/on-chain/${index + 1}`}>{index + 1}</Link>
                    </li>
                  ))}
                  <li>
                    {/* TODO */}
                    <Link
                      to={`/shop/${data?.profileUri}/on-chain/${Number(page) + 1}`}
                      style={Number(page) === pages ? { pointerEvents: 'none' } : {}}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
                      </svg>
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { OfficialItems };
