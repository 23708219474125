const Fallback = ({ text }) => {
  return (
    <div className="col-12 col-xl-9">
      <div className="row row--grid justify-content-center margin-responsive">
        <div>
          <div className="card" style={{ border: 0 }}>
            <p className="card__title m-0" style={{ fontSize: '24px' }}>
              {text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Fallback };
