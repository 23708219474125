import { useQuery } from '@tanstack/react-query';
import { buildUrl } from '../../all-items/utils';
import { BASE_URL } from '../../../../config';

const fetchData = async (url) => {
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

const useGetOrders = (filters, searchingPhrase) => {
  const ordersUrl = buildUrl(filters, `${BASE_URL}/users/orders`);
  const filteredOrdersUrl = ordersUrl + `&search=${searchingPhrase}`;

  const orders = useQuery({
    queryKey: ['ordersActivityPage'],
    queryFn: () => fetchData(filteredOrdersUrl),
  });

  return orders;
};
export { useGetOrders };
