import { memo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useGetUsers } from '../../pages/your-inventory/useGetUsers';
import { useStore } from '../../store';
import { ConnectWalletModal } from '../connect-wallet-modal';
import { ErrorModal } from '../error-modal';
import { SuccessModal } from '../success-modal';
import { AccordionButton } from './accordion-button';
import { Cart } from './cart';
import logo from './icons/logo.svg';

export function formatBitcoinAddress(address) {
  if (typeof address === 'string' && address.length >= 10) {
    const firstPart = address.substring(0, 5);
    const lastPart = address.substring(address.length - 5);

    return `${firstPart}...${lastPart}`;
  }
}

// TODO: osobna szukajka
const Header = memo(function Header() {
  const {
    auth: { status, address },
    setAuth,
    cart,
  } = useStore();

  const { pathname } = useLocation();

  const isExploreActive = pathname.includes('/explore');
  const isYourInventoryActive = pathname.includes('/your-inventory');
  const isStakingActive = pathname.includes('/staking');

  const result = useGetUsers();
  const { data: usersData } = result;

  // const handleInputChange = (e) => {
  //   setSearchingPhrase(() => e.target.value);
  // };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDisconnect = () => {
    setAuth({ status: 'not-authenticated', address: null });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const activeLinkStyles = {
    color: 'white',
    fontWeight: 'bold',
  };

  const connectButton = () => {
    return (
      <button className="header__action-btn header__action-btn--signin" onClick={handleClickOpen}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M19,7H18V6a3,3,0,0,0-3-3H5A3,3,0,0,0,2,6H2V18a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V10A3,3,0,0,0,19,7ZM5,5H15a1,1,0,0,1,1,1V7H5A1,1,0,0,1,5,5ZM20,15H19a1,1,0,0,1,0-2h1Zm0-4H19a3,3,0,0,0,0,6h1v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V8.83A3,3,0,0,0,5,9H19a1,1,0,0,1,1,1Z" />
        </svg>

        <span style={{ margin: '6px' }}>Connect</span>
      </button>
    );
  };

  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);

  const handleButtonClick = () => {
    setIsButtonActive(!isButtonActive);
    setIsMenuActive(!isMenuActive);
  };

  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [errorModalOpen, setErrorModalOpen] = useState(false);

  return (
    <div>
      <SuccessModal open={openSuccessModal} setOpen={setOpenSuccessModal}>
        <h4 className="sign__title" style={{ color: 'white', fontWeight: 'normal' }}>
          Purchase completed successfully
        </h4>
      </SuccessModal>
      <ErrorModal open={errorModalOpen} setOpen={setErrorModalOpen}>
        <h4 className="sign__title" style={{ color: 'white', textAlign: 'center', fontWeight: 'normal' }}>
          Some items in your cart were outdated and got removed
          <br /> Please try again
        </h4>
      </ErrorModal>
      <ConnectWalletModal handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} />

      <header className="header">
        <div className="header__content">
          <div className="header__logo">
            <Link to="/explore">
              <img src={logo} alt="" style={{ width: '80px', height: '80px' }} />
            </Link>
          </div>
          {/* <form action="#" className="header__search">
            onChange={handleInputChange} value={searchingPhrase}
            <input type="text" placeholder="Search items" />
            <button type="button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
              </svg>
            </button>
            <button type="button" className="close">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
              </svg>
            </button>
          </form> */}

          <div className={`header__menu ${isMenuActive ? 'header__menu--active' : ''}`}>
            <ul className="header__nav">
              {/* <li className="header__nav-item">
                <a href="/" className="header__nav-link">
                  Home
                </a>
              </li> */}
              <li className="header__nav-item">
                <Link to="/explore" className="header__nav-link" style={isExploreActive ? activeLinkStyles : {}}>
                  Explore
                </Link>
              </li>
              {/* <li className="header__nav-item">
                <Link to="/activity" className="header__nav-link">
                  Activity
                </Link>
              </li> */}
              <li className="header__nav-item">
                <Link
                  to="/your-inventory/not-minted/grouped/1"
                  className="header__nav-link"
                  style={isYourInventoryActive ? activeLinkStyles : {}}
                >
                  Inventory
                </Link>
              </li>
              <li className="header__nav-item">
                <Link to="/staking" className="header__nav-link" style={isStakingActive ? activeLinkStyles : {}}>
                  Stake $DRK
                </Link>
              </li>
              <li className="header__nav-item">
                <a
                  href="https://manual.dark.city/dark-market/"
                  target="_blank"
                  className="header__nav-link"
                  rel="noreferrer"
                >
                  How it works
                </a>
              </li>
              <li className="header__nav-item">
                <a
                  href="https://ordinalswallet.com/collection/tap-DRK"
                  target="_blank"
                  className="header__nav-link"
                  rel="noreferrer"
                >
                  Get $DRK
                </a>
              </li>
              {/* <li className="header__nav-item">
                <a href="https://connect.xverse.app/browser?url=dark-city-test.vercel.app" className="header__nav-link">
                  Open with Xverse App
                </a>
              </li> */}
            </ul>
          </div>

          <div className="header__actions"></div>
          {cartVisible && (
            <Cart
              setCartVisible={setCartVisible}
              setOpenSuccessModal={setOpenSuccessModal}
              setErrorModalOpen={setErrorModalOpen}
              usersData={usersData}
            />
          )}
          <div
            className="header__actions ml-0"
            style={{ alignSelf: 'start', marginTop: '14px', gap: '26px', alignItems: 'start' }}
          >
            {status === 'authenticated' ? (
              <>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="shopping-card" style={{ position: 'fixed' }}>
                      Shopping cart
                    </Tooltip>
                  }
                >
                  <div className="position-relative">
                    {cart.length > 0 && (
                      <div className="close-cart-button-badge">{cart.length < 10 ? cart.length : '9+'}</div>
                    )}
                    <button onClick={() => setCartVisible(true)} aria-label="Shopping cart" className="mt-1">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.29977 5H21L19 12H7.37671M20 16H8L6 3H3M9 20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z"
                          stroke="#6164ff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </OverlayTrigger>
                <AccordionButton text={formatBitcoinAddress(address)} onClick={handleDisconnect} />
              </>
            ) : (
              connectButton()
            )}
          </div>
          <button
            className={`header__btn ${isButtonActive ? 'header__btn--active' : ''}`}
            type="button"
            onClick={handleButtonClick}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </header>
    </div>
  );
});

export { Header };
