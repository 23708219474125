import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const location = useLocation();

  const excludeRegex = /\/your-inventory\/\d+$/;

  useEffect(() => {
    if (excludeRegex.test(location.pathname)) return;
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return null;
}
