import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const useSendQuiz = () => {
  const queryClient = useQueryClient();

  const sendQuizMutation = useMutation({
    mutationKey: ['sendQuiz'],

    mutationFn: async () => {
      const response = await fetch(`${BASE_URL}/stake/quiz`, {
        method: 'POST',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to send quiz');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stakeData']);
    },
  });

  return sendQuizMutation;
};

export { useSendQuiz };
