import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../../../config';

const useGiftDrk = () => {
  const queryClient = useQueryClient();

  const giftDrkMutation = useMutation({
    mutationKey: ['giftDrk'],
    mutationFn: async ({ amount, walletAddress }) => {
      const body = { amount: amount, walletAddress: walletAddress };
      const response = await fetch(`${BASE_URL}/transfers/drk`, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to gift DRK');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['orders']);
    },
  });

  return giftDrkMutation;
};

export { useGiftDrk };
